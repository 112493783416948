import { ToastType, Typography } from '@components';
import { TextInput } from '@components/forms';
import {
  carrierDetailsStore,
  updateCarrierComment,
  updateCarrierDetailsState,
} from '@store/carriers';
import { Comments } from '@store/orders';
import { Grid, Stack } from '@suid/material';
import { handleToast, isAdmin, isSuperAdmin } from '@utils/utils';
import { DateTime } from 'luxon';
import { Component, createSignal, onMount, Setter, Show } from 'solid-js';
import {
  canDeleteCarrierComment,
  isAdminOrOriginalAuthor,
} from '@views/carriers/carrierDetails/carrierDetailsTab/utils';
import { Delete } from '@suid/icons-material';
import { openDialogBox } from '@store/dialogBox';
import { DELETE_COMMENT_DIALOG_ID } from '@views/order/constants';
import {
  AddOrEditCommentPayload,
  customerDetailsStore,
  updateCustomerComment,
  updateCustomerDetailsState,
} from '@store/customers/customerDetails';
import AtgLoader from '@components/AtgLoader';

type SingleCommentCardProps = {
  comment: Comments;
  type:
    | 'Carrier'
    | 'Order'
    | 'Customer'
    | 'FactoringCompany'
    | 'Claim'
    | 'Call Log'
    | 'Prospect';
  setCommentIdToDelete: Setter<number | null>;
};

const textStyle = {
  color: 'black',
  fontSize: '16px',
  fontWeight: '400',
  flex: '1',
};
const titleStyle = { ...textStyle, fontWeight: '500' };

export const SingleCommentCard: Component<SingleCommentCardProps> = (props) => {
  const [editable, setEditable] = createSignal(false);
  const [comment, setComment] = createSignal('');
  const [loading, setLoading] = createSignal(false);

  onMount(() => {
    setComment(props.comment.comment ?? '');
  });

  const canEditComment = () => {
    switch (props.type) {
      case 'Customer':
      case 'Carrier':
        return isAdminOrOriginalAuthor(props.comment);
      default:
        return false;
    }
  };

  const canDelete = (comment: Comments) => {
    switch (props.type) {
      case 'Customer':
        return isAdminOrOriginalAuthor(comment);
      case 'Carrier':
        return canDeleteCarrierComment(comment);
      case 'Claim':
        return isSuperAdmin();
      case 'FactoringCompany':
        return isSuperAdmin();
      default:
        return isAdmin();
    }
  };

  const handleEditComment = () => {
    if (canEditComment()) setEditable(true);
  };

  const handleCommentSubmission = async (e: KeyboardEvent) => {
    if (e.key !== 'Enter' || e.shiftKey) return;
    setEditable(false);
    setLoading(true);
    const updateComment =
      props.type === 'Customer' ? updateCustomerComment : updateCarrierComment;
    const res = await updateComment({
      id: props.comment.id,
      comment: comment(),
      type: props.comment.type,
    } as AddOrEditCommentPayload);

    if (Boolean(res)) {
      if (props.type === 'Carrier') {
        const carrierDetails = carrierDetailsStore.carrierDetails;
        updateCarrierDetailsState({
          comments: carrierDetails?.comments.map((c) =>
            c.id === props.comment.id ? { ...c, comment: comment() } : c,
          ),
        });
      } else if (props.type === 'Customer') {
        const customerDetails = customerDetailsStore.customer;
        updateCustomerDetailsState({
          comments: customerDetails.comments.map((c) =>
            c.id === props.comment.id ? { ...c, comment: comment() } : c,
          ),
        });
      }

      handleToast(ToastType.Success, 'Comment updated successfully');
    } else {
      setComment(props.comment.comment ?? '');
    }
    setLoading(false);
  };

  return (
    <Stack spacing={1}>
      <Show when={loading()}>
        <AtgLoader />
      </Show>
      <Grid item container class="bg-[#EEE] p-1 h-[48px]">
        <Typography
          class="flex items-center h-full p-[5px]"
          variant="subtitle2"
          sxProps={titleStyle}
        >
          {props.comment.name ?? '-'}
        </Typography>
        <Grid
          xs={6}
          container
          gap={3}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Typography
            variant="body2"
            component="span"
            sxProps={{ ...textStyle, textAlign: 'end' }}
          >
            {DateTime.fromISO(props.comment.timestamp as string, {
              zone: 'utc',
            })
              .setZone('local')
              .toFormat('MMM dd, yyyy hh:mm a')
              .toUpperCase()}
          </Typography>
          {canDelete(props.comment) && (
            <Delete
              sx={{ color: '#B00020', cursor: 'pointer' }}
              onClick={() => {
                openDialogBox(`${DELETE_COMMENT_DIALOG_ID}-${props.type}`);
                props.setCommentIdToDelete(props.comment.id);
              }}
            />
          )}
        </Grid>
      </Grid>
      <Grid spacing={1} pb={3} class="!flex">
        <Show
          when={editable()}
          fallback={
            <span
              class={`${
                props.type === 'Customer' || props.type === 'Carrier'
                  ? 'cursor-pointer'
                  : ''
              }`}
              onClick={handleEditComment}
            >
              {comment()
                .split('\n')
                .map((line) => (
                  <span class="break-all">
                    {line}
                    <br />
                  </span>
                ))}
            </span>
          }
        >
          <TextInput
            multiline
            rows={3}
            inputProps={{
              style: {
                resize: 'vertical',
              },
            }}
            name="comment"
            label=""
            value={comment()}
            onChange={(value: string) => {
              setComment(value);
            }}
            onKeyDown={handleCommentSubmission}
            classes="!ml-2"
          />
        </Show>
      </Grid>
    </Stack>
  );
};
