import { LTLProductCatalog, MenuItemType, ToastType } from '@components';
import { createForm } from '@felte/solid';
import { validator } from '@felte/validator-yup';
import { closeModal } from '@store/modals';
import {
  CustomerAddress,
  EdiUpdate,
  LTLCatalogs,
  LastUsedTopStopModel,
  LoadModeTypes,
  OperationTypeV4,
  PickUpDropOffItem,
  featureFlagGetCustomerFeatures,
  orderStore,
  setOrderStore,
  updateLoadPropertyAtIndex,
} from '@store/orders';
import { convertDateTimeV2, handleToast, printLog } from '@utils/utils';
import find from 'lodash/find';
import get from 'lodash/get';
import { DateTime } from 'luxon';
import { Accessor, createEffect, createSignal, Setter } from 'solid-js';
import AddressBookGrid from '@views/customer/customerDetail/addressBookTab/AddressBookGrid';
import { Box } from '@suid/material';
import { ltlQuoteState, setLtlQuoteState } from '@store/ltl/store';

import { validations } from './validations';
import { IntialStopFormValues } from './types';
import { initialFormValues } from './defaultValues';
import { StopsMain } from './StopsMain';

export interface StopEditorProps {
  stop: LastUsedTopStopModel | null;
  hasDropOffStop: boolean;
  dropOffOptions: MenuItemType[];
  pickUpOptions: MenuItemType[];
  stopIds: { [key: string]: number };
  isSidebarRender?: boolean;
  mode: LoadModeTypes | undefined;
  currentNewStopId: number;
  currentNewLoadItemId: number;
  setActiveView: Setter<string>;
  activeView: Accessor<string>;
}

const StopEditor = (props: StopEditorProps) => {
  const [setAddressBook] = createSignal<CustomerAddress[]>([]);
  const [smallestLoadItemId, setSmallestLoadItemId] = createSignal<number>(
    props.currentNewLoadItemId,
  );

  const modalId = 'stops-modal';

  const { form, errors, data, setFields, isDirty, setIsDirty } =
    createForm<IntialStopFormValues>({
      initialValues: {
        ...initialFormValues,
        mode: orderStore.order.loads[orderStore.activeTab.index]?.mode,
        loadItems: [],
        shouldSendEdiBooleanVals: props.stop?.shouldSendEdiBooleanVals,
      },
      extend: validator({
        schema: validations(),
      }),
      onSubmit: () => {
        setOrderStore('isStopsEdited', isDirty());
        handleStopSubmit();
      },
    });

  createEffect(() => {
    if (props.stop) {
      setFieldsForEditStop(props.stop);
    }
    if (!props.stop && data().id !== props.currentNewStopId) {
      setFields('id', props.currentNewStopId);
    }
  });
  createEffect(() => {
    if (
      orderStore.order.loads[orderStore.activeTab.index].mode !== data().mode
    ) {
      setFields(
        'mode',
        orderStore.order.loads[orderStore.activeTab.index]
          .mode as LoadModeTypes,
      );
    }
  });

  // eslint-disable-next-line complexity
  const handleStopSubmit = () => {
    const stopsInStore = [
      ...(orderStore.order.loads[orderStore.activeTab.index].stops ?? []),
    ];
    const customerId = Boolean(orderStore.order.customerId)
      ? orderStore.order.customerId
      : 0;
    if (data().id === 0) {
      setFields('id', props.currentNewStopId);
    }
    const {
      loadItems,
      driverDateIn,
      driverDateOut,
      driverTimeIn,
      driverTimeOut,
      dateAppointment,
      timeAppointment,
      time,
      stopDateTime,
      ...rest
    } = data();
    let stop: Partial<LastUsedTopStopModel> = {
      ...rest,
      id: data().id,
      operationType: data().operationType as OperationTypeV4,
      customerId: customerId!,
      loadId: orderStore.order.loads[orderStore.activeTab.index].id,
      phone: data().phone.replace(/[^0-9]/g, ''),
      time: time,
      driverInTime:
        driverTimeIn === '' && driverDateIn !== ''
          ? convertDateTimeV2(driverDateIn, driverDateIn)
          : driverDateIn && driverTimeIn
            ? convertDateTimeV2(driverDateIn, driverTimeIn)
            : '',
      driverOutTime:
        driverTimeOut === '' && driverDateOut !== ''
          ? convertDateTimeV2(driverDateOut, driverDateOut)
          : driverDateOut && driverTimeOut
            ? convertDateTimeV2(driverDateOut, driverTimeOut)
            : '',
      stopDateTime: stopDateTime,
      appointmentDate:
        timeAppointment === '' && dateAppointment !== ''
          ? convertDateTimeV2(dateAppointment, dateAppointment)
          : timeAppointment && dateAppointment
            ? convertDateTimeV2(dateAppointment, timeAppointment)
            : '',
    };
    if (!props.stop) {
      stop.createdDate = new Date().toISOString();
      stop.timeZoneOffset = new Date().getTimezoneOffset();
      stop.driverOut = {
        date: DateTime.fromISO(driverDateOut).toFormat('MM/dd/yyyy'),
        time: DateTime.fromISO(driverTimeOut).toFormat('hh:mm a'),
      };
      stop.driverIn = {
        date: DateTime.fromISO(driverDateIn).toFormat('MM/dd/yyyy'),
        time: DateTime.fromISO(driverTimeIn).toFormat('hh:mm a'),
      };
      if (stop.needsAppointment ?? false) {
        stop.appointmentDate =
          timeAppointment === '' && dateAppointment !== ''
            ? convertDateTimeV2(dateAppointment, dateAppointment)
            : timeAppointment && dateAppointment
              ? convertDateTimeV2(dateAppointment, timeAppointment)
              : '';
        stop.appointment = {
          date: DateTime.fromISO(dateAppointment).toFormat('MM/dd/yyyy'),
          time: DateTime.fromISO(timeAppointment).toFormat('hh:mm a'),
        };
      }
      // These values are required for the UI to work
      stop.arrived = false;
      let highestStopOrder = -1;
      stopsInStore.forEach((stop) => {
        if ((stop.stopOrder ?? -1) > highestStopOrder) {
          highestStopOrder = stop.stopOrder ?? 0;
        }
      });
      stop.stopOrder = highestStopOrder + 1;
      stopsInStore.push(stop as LastUsedTopStopModel);
    } else {
      stop.modifiedDate = new Date().toISOString();
      if (stop.needsAppointment ?? false) {
        stop.appointmentDate =
          timeAppointment === '' && dateAppointment !== ''
            ? convertDateTimeV2(dateAppointment, dateAppointment)
            : timeAppointment && dateAppointment
              ? convertDateTimeV2(dateAppointment, timeAppointment)
              : '';
      }
      const index = stopsInStore.findIndex((itm) => itm.id === props.stop?.id);
      stop = { ...props.stop, ...stop };
      if (
        props.stop.id > 0 &&
        props.mode !== 'LTL' &&
        stop.shouldSendEdiBooleanVals
      ) {
        const ediUpdates = createShowAuto214(
          stop as LastUsedTopStopModel & {
            driverInTimeZone: string;
            driverOutTimeZone: string;
            appointmentTimeZone: string;
          },
        );
        stop.ediUpdates = ediUpdates;
        //NOTE: Show the toast only if there are EDI updates
        ediUpdates.length > 0 &&
          handleToast(
            ToastType.Info,
            'Automatic EDI update queued. Save the load to send it.',
          );
      }
      stopsInStore[index] = stop as LastUsedTopStopModel;
    }
    const { stops } = orderStore.order.loads[orderStore.activeTab.index];

    const firstRelevantStop = find(stops, (s) => {
      return s.dropOff === stop.pickUp && s.operationType !== 'Delete';
    });
    const items = loadItems.map((item) => {
      let itemLoadHazmat = item.loadItemHazmatDetail;
      if (itemLoadHazmat) {
        itemLoadHazmat = {
          ...itemLoadHazmat,
          contactNumber:
            item.loadItemHazmatDetail !== null &&
            item.loadItemHazmatDetail !== undefined
              ? // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                Boolean(item.loadItemHazmatDetail.contactNumber)
                ? item.loadItemHazmatDetail.contactNumber.replace(/[^0-9]/g, '')
                : ''
              : '',
        };
      }
      const itm = {
        ...item,
        loadItemHazmatDetail: itemLoadHazmat,
      };

      if (Boolean(stop.dropOff) && stop.id === itm.dropOffId) {
        itm.pickUpId =
          itm.pickUpId !== null
            ? itm.pickUpId
            : firstRelevantStop
              ? firstRelevantStop.id
              : null;
      } else if (Boolean(stop.pickUp) && stop.id === itm.pickUpId) {
        itm.dropOffId =
          itm.dropOffId !== null
            ? itm.dropOffId
            : firstRelevantStop
              ? firstRelevantStop.id
              : null;
      }
      return itm;
    });
    updateLoadPropertyAtIndex({ stops: stopsInStore, items: items });
    if (ltlQuoteState.quote) {
      const newQuoteInfo = { ...ltlQuoteState.quote };
      stopsInStore.forEach((stop) => {
        if (Boolean(stop.origin) || stop.pickUp) {
          newQuoteInfo.originCity = stop.city;
          newQuoteInfo.originState = stop.state;
          newQuoteInfo.originZip = stop.zip;
          newQuoteInfo.originCountry = stop.countryCode;
        } else if (Boolean(stop.destination) || stop.dropOff) {
          newQuoteInfo.destinationCity = stop.city;
          newQuoteInfo.destinationState = stop.state;
          newQuoteInfo.destinationZip = stop.zip;
          newQuoteInfo.destinationCountry = stop.countryCode;
        }
      });
      newQuoteInfo.loadItems = items
        .filter((item) => item.operationType !== 'Delete')
        .map((item) => {
          return {
            ...item,
            weight: Number(item.weight),
            type: item.type ?? undefined,
          };
        });
      setLtlQuoteState('quote', newQuoteInfo);
    }
    closeModal(modalId);
  };

  const checkCustomerFeatureFlags = (feature: string) => {
    return get(
      featureFlagGetCustomerFeatures,
      `featureFlagCustomer.${feature}`,
      false,
    );
  };
  // eslint-disable-next-line complexity
  const createShowAuto214 = (
    stop: LastUsedTopStopModel & {
      driverInTimeZone: string;
      driverOutTimeZone: string;
      appointmentTimeZone: string;
    },
  ) => {
    let ediUpdates = stop.ediUpdates ? [...stop.ediUpdates] : [];
    if (stop.pickUp) {
      ediUpdates = ediUpdates.filter(
        (update) =>
          !['X1', 'CD', 'AB'].includes(update.shipmentStatus ?? '') &&
          update.appointmentStatus !== 'AB',
      );
    } else if (stop.dropOff) {
      ediUpdates = ediUpdates.filter(
        (update) =>
          !['X3', 'AF', 'AA'].includes(update.shipmentStatus ?? '') &&
          update.appointmentStatus !== 'AA',
      );
    }
    //remove the edi updates that are not relevant to the current stop
    const findIndexByKey = (key: keyof EdiUpdate, value: string) => {
      return ediUpdates.findIndex((item) => item[key] === value);
    };

    const addOrUpdateEdiUpdate = (
      key: keyof EdiUpdate,
      value: string,
      update: EdiUpdate,
    ) => {
      const index = findIndexByKey(key, value);
      if (index === -1) {
        ediUpdates.push(update);
      } else {
        ediUpdates[index] = { ...ediUpdates[index], ...update };
      }
    };

    const { driverInTimeZone, driverOutTimeZone, appointmentTimeZone } = stop;
    //Note: THese are required for any EDI Update
    const defaultEdiProperties = {
      latitude: null,
      longitude: null,
      note: null,
      auto214: true,
      statusReason: 'NS',
      temperature: null,
      stopId: stop.id,
      loadId: stop.loadId,
      state: stop.state ?? '',
      city: stop.city ?? '',
    };
    if (stop.pickUp) {
      const {
        shouldSendPickupAppointmentAuto214,
        shouldSendPickupDriverInAuto214,
        shouldSendPickupDriverOutAuto214,
      } = stop.shouldSendEdiBooleanVals;

      if (
        shouldSendPickupDriverInAuto214 &&
        checkCustomerFeatureFlags('EDIPickupDriverInAuto214')
      ) {
        addOrUpdateEdiUpdate('shipmentStatus', 'X3', {
          shipmentStatus: 'X3',
          appointmentStatus: null,
          date: stop.driverInTime as string,
          appointmentNumber: null,
          timeZone: driverInTimeZone || 'LT',
          ...defaultEdiProperties,
        });
      }
      if (
        shouldSendPickupDriverOutAuto214 &&
        checkCustomerFeatureFlags('EDIPickupDriverOutAuto214')
      ) {
        addOrUpdateEdiUpdate('shipmentStatus', 'AF', {
          shipmentStatus: 'AF',
          appointmentStatus: null,
          date: stop.driverOutTime as string,
          appointmentNumber: null,
          timeZone: driverOutTimeZone || 'LT',
          ...defaultEdiProperties,
        });
      }
      if (
        shouldSendPickupAppointmentAuto214 &&
        checkCustomerFeatureFlags('EDIPickupAppointmentAuto214')
      ) {
        addOrUpdateEdiUpdate('appointmentStatus', 'AA', {
          shipmentStatus: null,
          appointmentStatus: 'AA',
          date: stop.appointmentDate as string,
          appointmentNumber: stop.appointmentNum as string,
          timeZone: appointmentTimeZone || 'LT',
          ...defaultEdiProperties,
        });
      }
    }

    if (stop.dropOff) {
      const {
        shouldSendDropoffAppointmentAuto214,
        shouldSendDropoffDriverInAuto214,
        shouldSendDropoffDriverOutAuto214,
      } = stop.shouldSendEdiBooleanVals;
      if (
        shouldSendDropoffDriverInAuto214 &&
        checkCustomerFeatureFlags('EDIDropoffDriverInAuto214')
      ) {
        addOrUpdateEdiUpdate('shipmentStatus', 'X1', {
          shipmentStatus: 'X1',
          appointmentStatus: null,
          date: stop.driverInTime as string,
          appointmentNumber: null,
          timeZone: driverInTimeZone || 'LT',
          ...defaultEdiProperties,
        });
      }
      if (
        shouldSendDropoffDriverOutAuto214 &&
        checkCustomerFeatureFlags('EDIDropoffDriverOutAuto214')
      ) {
        addOrUpdateEdiUpdate('shipmentStatus', 'CD', {
          shipmentStatus: 'CD',
          appointmentStatus: null,
          date: stop.driverOutTime as string,
          appointmentNumber: null,
          timeZone: driverOutTimeZone || 'LT',
          ...defaultEdiProperties,
        });
      }
      if (
        shouldSendDropoffAppointmentAuto214 &&
        checkCustomerFeatureFlags('EDIDropoffAppointmentAuto214')
      ) {
        addOrUpdateEdiUpdate('appointmentStatus', 'AB', {
          shipmentStatus: null,
          appointmentStatus: 'AB',
          date: stop.appointmentDate as string,
          appointmentNumber: stop.appointmentNum as string,
          timeZone: appointmentTimeZone || 'LT',
          ...defaultEdiProperties,
        });
      }
    }
    // Remove EDI updates if the corresponding shouldSend flags are false
    ediUpdates = ediUpdates.filter((update) => {
      if (
        update.shipmentStatus === 'X3' &&
        !stop.shouldSendEdiBooleanVals.shouldSendPickupDriverInAuto214
      ) {
        return false;
      }
      if (
        update.shipmentStatus === 'AF' &&
        !stop.shouldSendEdiBooleanVals.shouldSendPickupDriverOutAuto214
      ) {
        return false;
      }
      if (
        update.appointmentStatus === 'AA' &&
        !stop.shouldSendEdiBooleanVals.shouldSendPickupAppointmentAuto214
      ) {
        return false;
      }
      if (
        update.shipmentStatus === 'X1' &&
        !stop.shouldSendEdiBooleanVals.shouldSendDropoffDriverInAuto214
      ) {
        return false;
      }
      if (
        update.shipmentStatus === 'CD' &&
        !stop.shouldSendEdiBooleanVals.shouldSendDropoffDriverOutAuto214
      ) {
        return false;
      }
      if (
        update.appointmentStatus === 'AB' &&
        !stop.shouldSendEdiBooleanVals.shouldSendDropoffAppointmentAuto214
      ) {
        return false;
      }
      return true;
    });
    return ediUpdates;
  };

  // eslint-disable-next-line complexity
  const setFieldsForEditStop = (stop: LastUsedTopStopModel) => {
    setFields('address1', stop.address1 ?? '');
    setFields('address2', stop.address2 ?? '');
    setFields('city', stop.city ?? '');
    setFields('state', stop.state ?? '');
    setFields('zip', stop.zip);
    setFields('contact', stop.contact ?? '');
    setFields('locationName', stop.locationName ?? '');
    setFields('phone', stop.phone ?? '');
    setFields('email', stop.email ?? '');
    setFields('description', stop.description ?? '');
    setFields('instructions', stop.instructions ?? '');
    setFields('directions', stop.directions ?? '');
    setFields('stopDateTime', stop.stopDateTime ?? '');
    setFields('dateAppointment', stop.appointmentDate ?? '');
    setFields('timeAppointment', stop.appointmentDate ?? '');
    setFields('referenceNum', stop.referenceNum ?? '');
    setFields('time', stop.time ?? '');
    setFields('pickUp', stop.pickUp);
    setFields('dropOff', stop.dropOff);
    setFields('countryCode', stop.countryCode ?? 'USA');
    setFields('driverDateIn', stop.driverInTime ?? '');
    setFields('driverTimeIn', stop.driverInTime ?? '');
    setFields('driverDateOut', stop.driverOutTime ?? '');
    setFields('driverTimeOut', stop.driverOutTime ?? '');
    setFields('needsAppointment', stop.needsAppointment);
    setFields(
      'operationType',
      stop.operationType === 'None' ? 'Update' : stop.operationType,
    );
    setFields('id', stop.id);
    setFields(
      'loadItems',
      orderStore.order.loads[orderStore.activeTab.index].items ?? [],
    );
    setFields('appointmentNum', stop.appointmentNum ?? '');
    if (orderStore.order.edi) {
      setFields('stopCode', stop.stopCode ?? '');
      setFields('proprietaryCode', stop.proprietaryCode ?? '');
    }

    // Setting for Edi updates
    setFields('shouldSendEdiBooleanVals', stop.shouldSendEdiBooleanVals);
  };

  const addListItemFromLTLCatalog = (item: LTLCatalogs) => {
    setFields('loadItems', [
      ...data().loadItems,
      {
        item: item.unitType || 'Pallets',
        class: item.class?.toString(),
        nmfc: item.nMFC,
        description: item.description,
        unitType: item.unitType,
        lengthInch: item.lengthInches,
        widthInch: item.widthInches,
        heightInch: item.heightInches,
        id: smallestLoadItemId(),
        operationType: 'Insert',
        loadId: orderStore.order.loads[orderStore.activeTab.index].id,
        dropOffId: data().dropOff ? data().id : null,
        pickUpId: data().pickUp ? data().id : null,
        quantity: 1,
        weight: Number(item.weight) || 0,
        pieces: null,
        loadItemHazmatDetail: item.hazmat
          ? {
              hazardClass: item.hazardClass || '',
              packingGroup: item.packingGroup || '',
              unNumber: item.uNNumber || '',
              contactName: item.contactName || '',
              contactNumber: item.contactNumber || '',
              piece: item.piece || '',
            }
          : null,
      },
    ] as PickUpDropOffItem[]);
    setSmallestLoadItemId(smallestLoadItemId() - 1);
    props.setActiveView('stops-main');
  };

  const handleAddressClick = (rowData: CustomerAddress) => {
    setFields('locationName', rowData.name ?? '');
    setFields('address1', rowData.addressLine1 ?? '');
    setFields('address2', rowData.addressLine2 ?? '');
    setFields('city', rowData.city ?? '');
    setFields('state', rowData.state ?? '');
    setFields('zip', rowData.zip ?? '');
    setFields('contact', rowData.contactName ?? '');
    setFields('phone', rowData.contactPhone ?? '');
    setFields('instructions', rowData.specialInstructions ?? '');
    setFields('description', rowData.internalNote ?? '');
    setFields('directions', rowData.drivingDirections ?? '');
    if (props.mode === 'LTL') {
      printLog('rowData', rowData);
      const openTimeFrom =
        rowData.openTimeFrom !== undefined && rowData.openTimeFrom !== null
          ? rowData.openTimeFrom.toString()
          : '';
      setFields('driverTimeIn', openTimeFrom);
      const openTimeTo =
        rowData.openTimeTo !== undefined && rowData.openTimeTo !== null
          ? rowData.openTimeTo.toString()
          : '';
      setFields('driverTimeOut', openTimeTo);
    }
    props.setActiveView('stops-main');
  };

  const createInitialStopValsForEdi = () => {
    const { quoteStops = [] } =
      orderStore.order.loads[orderStore.activeTab.index];
    const stop = quoteStops.find((stop) => stop.id === props.stop?.id);
    return {
      driverDateIn: stop?.driverInTime ?? '',
      driverDateOut: stop?.driverOutTime ?? '',
      appointmentNum: stop?.appointmentNum ?? '',
      appointmentDate: stop?.appointmentDate ?? '',
    };
  };
  return (
    <>
      <div class={props.activeView() === 'stops-main' ? 'block p-2' : 'hidden'}>
        <StopsMain
          hasDropOffStop={props.hasDropOffStop}
          form={form}
          setActiveView={props.setActiveView}
          setAddressBook={setAddressBook}
          errors={
            errors as unknown as Accessor<
              Record<keyof IntialStopFormValues, string[] | null>
            >
          }
          setIsDirty={setIsDirty}
          data={data}
          setFields={setFields}
          setSmallestLoadItemId={setSmallestLoadItemId}
          smallestLoadItemId={smallestLoadItemId}
          modalId={modalId}
          dropOffOptions={props.dropOffOptions}
          pickUpOptions={props.pickUpOptions}
          mode={props.mode!}
          stop={props.stop}
          initialStopVals={createInitialStopValsForEdi()}
          stopIds={props.stopIds}
        />
      </div>
      <div
        class={
          props.activeView() === 'ltl-product-catalog' ? 'block' : 'hidden'
        }
      >
        <LTLProductCatalog
          customerId={orderStore.order.customerId!}
          goBackButtonText="< Back to Stops"
          goBack={() => props.setActiveView('stops-main')}
          onRowClick={(rowData: LTLCatalogs) => {
            addListItemFromLTLCatalog(rowData);
          }}
        />
      </div>
      <div
        class={
          props.activeView() === 'select-from-addresses' ? 'block' : 'hidden'
        }
      >
        <Box class="ag-theme-alpine" height={550}>
          <AddressBookGrid
            onRowClick={(rowData: CustomerAddress) => {
              handleAddressClick(rowData);
            }}
            customerId={orderStore.order.customerId ?? undefined}
          />
        </Box>
      </div>
    </>
  );
};

export default StopEditor;
