import { ColDef } from '@ag-grid-community/core';
import AgGridSolid from '@ag-grid-community/solid';
import { agGridCellStyle } from '@components/Grid/AgGridCellStyle';
import {
  columnsToolPanel,
  filtersToolPanel,
} from '@components/Grid/components/constants';
import { useParams } from '@solidjs/router';
import {
  FactoringCompanyDetailsEditHistory,
  fetchFactoringCompanyDetailsEditHistory,
} from '@store/factoringCompany/factoringCompanyDetails';
import { Notification } from '@components';
import { dateComparator } from '@store/loadboard/utils';
import { Grid } from '@suid/material';
import { atgLogo } from '@views/ltl';
import { DateTime } from 'luxon';
import { Component, createSignal } from 'solid-js';
import { getRowGroupStyle } from '@components/Grid/utils';

const commonColumnDefs: ColDef = {
  enableRowGroup: true,
  filter: 'agTextColumnFilter',
  cellStyle: agGridCellStyle,
  sortable: true,
  autoHeight: true,
};

const columnDefs: ColDef[] = [
  {
    ...commonColumnDefs,
    field: 'timeStamp',
    headerName: 'Timestamp',
    rowGroup: true,
    valueFormatter: (params: { value: string }) => {
      return params.value
        ? DateTime.fromISO(params.value).toFormat('yyyy-MM-dd HH:mm:ss a')
        : '';
    },
    minWidth: 210,
    hide: true,
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: dateComparator,
      buttons: ['reset'],
      suppressAndOrCondition: true,
    },
    sort: 'asc',
  },
  {
    ...commonColumnDefs,
    field: 'action',
    headerName: 'Action',
  },
  {
    ...commonColumnDefs,
    field: 'historyType',
    headerName: 'Item',
  },
  {
    ...commonColumnDefs,
    field: 'oldValue',
    headerName: 'Old Value',
  },
  {
    ...commonColumnDefs,
    field: 'newValue',
    headerName: 'New Value',
  },
  {
    ...commonColumnDefs,
    field: 'editedBy',
    headerName: 'Edited By',
  },
  {
    ...commonColumnDefs,
    field: 'description',
    headerName: 'Description',
    minWidth: 220,
    hide: true,
  },
];

export const FactoringCompanyEditHistory: Component = () => {
  const params = useParams();
  const [editHistory, setEditHistory] = createSignal<
    FactoringCompanyDetailsEditHistory[]
  >([]);

  return (
    <Grid class="ag-theme-alpine ag-theme-custom-border custom-ag-row-icon-grid my-row-group-border flex !flex-col !flex-1 h-[calc(100vh-160px)]">
      <Notification
        type="warning"
        text="Edits may take up to 15 minutes to appear. Please wait for the table to update."
        sxProps="!mb-2"
      />
      <AgGridSolid
        overlayNoRowsTemplate="No data available"
        overlayLoadingTemplate={atgLogo}
        columnDefs={columnDefs}
        rowData={editHistory()}
        getRowStyle={getRowGroupStyle}
        gridOptions={{
          groupDefaultExpanded: 1,
          autoGroupColumnDef: {
            headerName: 'Timestamp',
            minWidth: 200,
          },
          pagination: true,
          groupDisplayType: 'groupRows',
          suppressPaginationPanel: false,
          rowGroupPanelShow: 'always',
          sideBar: {
            toolPanels: [columnsToolPanel, filtersToolPanel],
          },
          onGridReady: async (gridParams) => {
            gridParams.api.sizeColumnsToFit();
            gridParams.api.showLoadingOverlay();
            const res = await fetchFactoringCompanyDetailsEditHistory(
              params.id,
            );
            res && setEditHistory(res);
            gridParams.api.hideOverlay();
          },
        }}
        class="h-full"
      />
    </Grid>
  );
};
