//TODO : ADD USAGE
//USAGE EXAMPLE
//FOR REFERENCE CHECK AddEditDocument.tsx File
//FOR REFERENCE CHECK ImportCatalogItem.tsx File

import { FileUploadErrorIcon } from '@assets';
import { CategoryT } from '@common/types';
import { DialogBox } from '@components/DialogBox';
import { LinkComponent } from '@components/Link';
import { domainUrl } from '@constants/urls';
import { Delete } from '@suid/icons-material';
import InfoIcon from '@suid/icons-material/Info';
import {
  Box,
  CircularProgress,
  TextField as FileUploadInput,
  FormControl,
  FormLabel,
  Grid,
} from '@suid/material';
import {
  Component,
  createSignal,
  For,
  mergeProps,
  onCleanup,
  onMount,
  Show,
} from 'solid-js';

import { HoverPop } from '../HoverPop/HoverPop';
import { FileCategory } from './FileCategory';
import { classes } from './FileUpload.style';
import { DocumentModel } from './types';

export type CommonFileUploadFiles = {
  name: string;
  dateAdded?: string;
  id?: number;
  url?: string;
  size?: number;
};

export type FileTypes =
  | 'pdf'
  | 'jpeg'
  | 'jpg'
  | 'png'
  | 'doc'
  | 'docx'
  | 'csv'
  | 'xls'
  | 'xlsx'
  | 'ppt'
  | 'pptx'
  | 'txt'
  | 'tif'
  | 'xks'
  | 'html'
  | 'msg'
  | 'odt';
// Add more file types as needed

export type Props = {
  removeFile?: (
    index: number,
    item: CommonFileUploadFiles,
  ) => Promise<void> | void;
  maxFileSize?: number;
  acceptedFileTypes?: FileTypes[];
  isRemoveFile?: boolean;
  shouldUploadOnSelect?: boolean;
  uploadedFiles?: CommonFileUploadFiles[];
  apiCallBackFn?: (file: File) => Promise<void>;
  error?: string | string[] | null;
  onSelectedFile?: (file: File) => void;
  onSelectedFileRemove?: () => void;
  customValidation?: (file: File) => boolean;
  isMultipleUpload?: boolean;
  height?: number;
  width?: number;
  label?: string;
  disabled?: boolean;
  alreadyUploadedDocuments?: DocumentModel[];
  required?: boolean;
  showAttachFiles?: boolean;
  fileRestrictionMessage?: string;
  categories?: CategoryT[];
  isAdmin?: boolean;
};

const DEFAULT_LABEL = 'Click or Drag a File To Upload';
const DEFAULT_HEIGHT = 150;
const DEFAULT_WIDTH = 100;
const DEFAULT_MAX_SIZE = 5242880;

const CommonFileUpload: Component<Props> = (props) => {
  props = mergeProps({ maxFileSize: 5242880 }, props);

  const [loading, setLoading] = createSignal(false);
  const [invalidFile, setInvalidFile] = createSignal<
    'fileType' | 'fileSize' | 'none'
  >('none');
  const [fileToDelete, setFileToDelete] = createSignal<{
    index: number;
    file: CommonFileUploadFiles;
  } | null>(null);
  const [inputRef, setInputRef] = createSignal<HTMLInputElement>();
  const [dropZoneRef, setDropZoneRef] = createSignal<HTMLDivElement>();
  const [buttonRef, setButtonRef] = createSignal<HTMLDivElement>();
  const [getIsDialogOpen, setIsDialogOpen] = createSignal(false);
  const [invalidFileTypeErrors, setInvalidFileTypeErrors] = createSignal<{
    customErrors: string[];
    fileTypeErrors: string[];
    fileSizeErrors: string[];
  }>({
    customErrors: [],
    fileTypeErrors: [],
    fileSizeErrors: [],
  });

  const handleFileValidation = (file: File | null) => {
    if (!file) {
      return {
        isCustom: false,
        isFileType: false,
        isFileSize: false,
      };
    }

    const isCustom = props.customValidation
      ? props.customValidation(file)
      : true;
    const isFileType = checkFileType(file);
    const isFileSize = file.size <= (props.maxFileSize ?? DEFAULT_MAX_SIZE);

    return {
      isCustom,
      isFileType,
      isFileSize,
    };
  };

  const handleFileChangeOrDrop = async (
    file: File | null,
    e: DragEvent | Event,
  ) => {
    const { isCustom, isFileType, isFileSize } = handleFileValidation(file);
    if (!isCustom || !Boolean(isFileType) || !isFileSize) {
      setInvalidFileTypeErrors((prev) => ({
        ...prev,
        customErrors: !isCustom
          ? [...prev.customErrors, file?.name as string]
          : prev.customErrors,
        fileTypeErrors: !Boolean(isFileType)
          ? [...prev.fileTypeErrors, file?.name as string]
          : prev.fileTypeErrors,
        fileSizeErrors: !isFileSize
          ? [...prev.fileSizeErrors, file?.name as string]
          : prev.fileSizeErrors,
      }));

      if (!isFileSize) setInvalidFile('fileSize');
      if (!Boolean(isFileType)) setInvalidFile('fileType');
      return;
    }

    const shouldUpload =
      Boolean(props.shouldUploadOnSelect) || Boolean(props.isMultipleUpload);

    if (!shouldUpload) {
      props.onSelectedFile?.(file!);
    } else {
      props.apiCallBackFn && (await props.apiCallBackFn(file!));
    }
    // TO clear the input value after file upload or drop
    if (e.target) {
      (e.target as HTMLInputElement).value = '';
    }
  };

  const handleChange = async (e: Event) => {
    try {
      setLoading(true);
      setInvalidFile('none');
      setInvalidFileTypeErrors({
        customErrors: [],
        fileTypeErrors: [],
        fileSizeErrors: [],
      });
      const target = e.target as HTMLInputElement;
      if (Boolean(props.isMultipleUpload)) {
        const filePromises = [];
        for (let i = 0; i < (target.files?.length ?? 0); i++) {
          filePromises.push(
            handleFileChangeOrDrop(target.files?.[i] ?? null, e),
          );
        }
        await Promise.all(filePromises);
      } else {
        const file = target.files?.[0];
        await handleFileChangeOrDrop(file!, e);
      }
    } finally {
      setLoading(false);
      const target = e.target as HTMLInputElement;
      target.value = '';
    }
  };

  const handleDrop = async (e: DragEvent) => {
    e.preventDefault();
    setInvalidFile('none');
    setLoading(true);
    const dropZone = dropZoneRef();

    if (dropZone) {
      dropZone.style.backgroundColor = 'rgb(242, 246, 248)';
      dropZone.style.border = '3px solid transparent';
    }
    if (Boolean(props.isMultipleUpload)) {
      const filePromises = [];
      for (let i = 0; i < (e.dataTransfer?.files.length ?? 0); i++) {
        filePromises.push(
          handleFileChangeOrDrop(e.dataTransfer?.files[i] ?? null, e),
        );
      }
      await Promise.all(filePromises);
    } else {
      const file = e.dataTransfer?.files[0];
      await handleFileChangeOrDrop(file!, e);
    }
    setLoading(false);
  };

  const checkFileType = (file: File) => {
    const fileExtension = file.name.split('.').pop();
    if (
      !Boolean(props.acceptedFileTypes) ||
      props.acceptedFileTypes?.length === 0
    )
      return true;
    return props.acceptedFileTypes?.includes(fileExtension as FileTypes);
  };

  const handleDelete = async () => {
    if (fileToDelete()) {
      const index = fileToDelete()?.index;
      const file = fileToDelete()?.file;
      if (index !== undefined && file !== undefined) {
        if (props.removeFile) {
          await props.removeFile(Number(index), file);
        }
      }
      setFileToDelete(null);
    }
  };

  const removeUploadedFile = (index: number, file: CommonFileUploadFiles) => {
    setFileToDelete({
      index: index,
      file,
    });
    // openDialogBox('customFileUploadDeleteDialog');
    setIsDialogOpen(true);
  };

  const handleDragOver = (e: DragEvent) => {
    e.preventDefault();
    const dropZone = dropZoneRef();

    if (dropZone) {
      dropZone.style.backgroundColor = '#d3ebf0';
      dropZone.style.border = '3px solid #026ea1';
    }
  };

  const handleDragLeave = (e: DragEvent) => {
    e.preventDefault();
    const dropZone = dropZoneRef();

    if (dropZone) {
      dropZone.style.backgroundColor = 'rgb(242, 246, 248)';
      dropZone.style.border = '3px solid transparent';
    }
  };

  onMount(() => {
    const dropZone = dropZoneRef();

    if (dropZone) {
      dropZone.addEventListener('dragover', handleDragOver);
      dropZone.addEventListener('dragenter', handleDragOver);
      dropZone.addEventListener('dragleave', handleDragLeave);
      dropZone.addEventListener('drop', handleDrop);
      dropZone.style.transition = '0.2s ease-in-out';
      dropZone.style.border = '3px solid transparent';
      dropZone.style.borderRadius = '6px';
    }
    buttonRef()?.addEventListener('click', () => {
      inputRef()?.click();
    });
  });

  onCleanup(() => {
    dropZoneRef()?.removeEventListener('dragover', handleDragOver);
    dropZoneRef()?.removeEventListener('dragenter', handleDragOver);
    dropZoneRef()?.removeEventListener('dragleave', handleDragLeave);
    dropZoneRef()?.removeEventListener('drop', handleDrop);
  });

  return (
    <>
      <FormControl
        variant="standard"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        ref={setDropZoneRef}
        class="w-full h-full"
      >
        <Grid
          container
          sx={classes.iconLabelContainer(props.height ?? DEFAULT_HEIGHT)}
          ref={setButtonRef}
        >
          <FormLabel sx={classes.inputLabelStyles(props.disabled ?? false)}>
            {loading() ? (
              <>Files are uploading please wait....</>
            ) : (
              props.label ?? (
                <div class="flex items-center">
                  <div>{DEFAULT_LABEL}</div>
                  <div class="relative z-10 ml-2">
                    <HoverPop anchor={<InfoIcon />} render={true}>
                      <div class="p-2">
                        {Boolean(props.fileRestrictionMessage)
                          ? props.fileRestrictionMessage
                          : 'SVG, PNG, JPG, GIF, CSV, TIFF, BMP, or, PDF (max. 5mb)'}
                      </div>
                    </HoverPop>
                  </div>
                </div>
              )
            )}
          </FormLabel>
        </Grid>
        <FileUploadInput
          inputProps={{
            multiple: props.isMultipleUpload,
            accept:
              props.acceptedFileTypes?.map((type) => `.${type}`).join(',') ??
              '*',
          }}
          inputRef={setInputRef}
          type="file"
          sx={classes.fileUploadInputStyles(
            props.height ?? DEFAULT_HEIGHT,
            props.width ?? DEFAULT_WIDTH,
          )}
          disabled={(props.disabled ?? false) || loading()}
          onChange={handleChange}
          required={props.required}
        />
        {invalidFile() !== 'none' && (
          <Box
            backgroundColor="#FFD8DF"
            displayRaw="flex"
            alignItems="center"
            justifyContent="center"
            p={1}
            gap={2}
          >
            <span class="text-[#952828] font-bold">Status:</span>
            <span class="flex-1">
              <span class="font-medium">
                {invalidFile() === 'fileType'
                  ? 'Invalid File Type'
                  : 'File Size Exceeded'}{' '}
              </span>
              <Show when={Boolean(props.isMultipleUpload)}>
                <ul class="list-disc ml-5">
                  <For
                    each={
                      invalidFile() === 'fileType'
                        ? invalidFileTypeErrors().fileTypeErrors
                        : invalidFileTypeErrors().fileSizeErrors
                    }
                  >
                    {(error) => {
                      return (
                        <li class="break-all">
                          <span>{error}</span>
                        </li>
                      );
                    }}
                  </For>
                </ul>
              </Show>
            </span>
            <span>
              <img src={FileUploadErrorIcon} alt="File Upload Error" />
            </span>
          </Box>
        )}
        <Box class="max-h-[400px] overflow-y-auto py-2">
          <Show when={!Boolean(props.categories)}>
            <For each={props.uploadedFiles}>
              {(file, index) => {
                return (
                  <Box displayRaw="flex" alignItems="center" p={1} gap={2}>
                    <Box displayRaw="flex" gap={2} alignItems="center">
                      {Boolean(file.url) ? (
                        <Box class="text-left break-all">
                          <LinkComponent
                            url={`${domainUrl}${file.url}`}
                            target={'_blank'}
                            title={file.name}
                          />
                        </Box>
                      ) : (
                        <span>{file.name}</span>
                      )}
                    </Box>
                    <Show
                      when={
                        Boolean(props.isRemoveFile) && Boolean(props.isAdmin)
                      }
                    >
                      <Delete
                        class="text-[#B00020] cursor-pointer"
                        onclick={() => removeUploadedFile(index(), file)}
                      />
                    </Show>
                  </Box>
                );
              }}
            </For>
          </Show>
          <Show when={Boolean(props.categories)}>
            <For each={props.categories}>
              {(category) => {
                return (
                  <Box>
                    <FileCategory
                      category={category}
                      files={props.uploadedFiles ?? []}
                      onUpload={() => {
                        inputRef()?.click();
                      }}
                      onDelete={(file) => {
                        const fileIdx = props.uploadedFiles?.findIndex(
                          (f) => f.name === file.name,
                        );

                        if (fileIdx !== undefined && fileIdx >= 0) {
                          removeUploadedFile(fileIdx, file);
                        }
                      }}
                    />
                  </Box>
                );
              }}
            </For>
          </Show>
        </Box>
        <Show when={loading()}>
          <Box sx={classes.BoxStyles()}>
            <Box>
              <CircularProgress size={50} />
            </Box>
          </Box>
        </Show>
      </FormControl>
      <DialogBox
        id="customFileUploadDeleteDialog"
        title="Are you sure you want to delete this document?"
        onSubmit={handleDelete}
        onSubmitText="Delete"
        onCancel={() => setFileToDelete(null)}
        isDialogOpen={getIsDialogOpen()}
        closeDialogBox={() => setIsDialogOpen(false)}
      />
    </>
  );
};

export default CommonFileUpload;
