import { Card, ToastType } from '@components';
import CommonFileUpload, {
  CommonFileUploadFiles,
} from '@components/FileUpload/CommonFileUpload';
import { useParams } from '@solidjs/router';
import {
  deleteFactoringCompanyDetailsDocument,
  factoringCompanyDetailsStore,
  getFactoringCompanyDetailsDocuments,
  setFactoringCompanyDetailsStore,
  uploadFactoringCompanyDetailsDocument,
} from '@store/factoringCompany/factoringCompanyDetails';
import { handleToast, isFullAdmin } from '@utils/utils';
import { keys, last } from 'lodash';
import { Component, onMount } from 'solid-js';
import { produce } from 'solid-js/store';

export const FactoringCompanyDetailsDocumentsCard: Component = () => {
  const params = useParams();

  const handleFileUpload = async (file: File) => {
    try {
      setFactoringCompanyDetailsStore('documents', [
        {
          dateAdded: new Date().toISOString(),
          id: factoringCompanyDetailsStore.documents.length + 1,
          name: file.name,
          url: '',
        },
        ...factoringCompanyDetailsStore.documents,
      ]);
      const res = await uploadFactoringCompanyDetailsDocument(
        factoringCompanyDetailsStore.factoringCompanyDetails.id,
        file,
      );
      if (Boolean(res)) {
        const name =
          typeof res.results[0].url == 'string'
            ? last(res.results[0].url.split('/'))
            : keys(res.results[0].url)[0];

        setFactoringCompanyDetailsStore(
          produce((state) => {
            const newFiles = [...state.documents];
            const objectIndex = newFiles.findIndex((f) => f.name === file.name);
            if (objectIndex !== -1) {
              newFiles[objectIndex].url = res.results[0].url;
              newFiles[objectIndex].name = name!;
            }
            state.documents = newFiles;
          }),
        );
        handleToast(ToastType.Success, 'Factoring company document uploaded');
      }
    } catch (error: unknown) {
      handleToast(
        ToastType.Error,
        'Uploading factoring company document failed',
      );
      setFactoringCompanyDetailsStore(
        produce((state) => {
          state.documents = state.documents.filter((f) => f.name !== file.name);
        }),
      );
    }
  };

  const handleFileRemove = async (
    index: number,
    item: CommonFileUploadFiles,
  ) => {
    try {
      const urlParams = new URLSearchParams(item.url?.split('?')[1]);
      const payload = {
        file: urlParams.get('url')!,
      };
      await deleteFactoringCompanyDetailsDocument(payload);
      setFactoringCompanyDetailsStore(
        produce((state) => {
          const newFiles = [...state.documents];
          newFiles.splice(index, 1);
          state.documents = newFiles;
        }),
      );
      handleToast(ToastType.Success, 'Factoring company document deleted');
    } catch (error: unknown) {
      handleToast(
        ToastType.Error,
        'Deleting factoring company document failed',
      );
    }
  };

  const customValidation = (file: File) => {
    if (
      factoringCompanyDetailsStore.documents.some(
        (f) => f.name.split('_')[1] === file.name,
      )
    ) {
      handleToast(ToastType.Error, 'File already exists');
      return false;
    }
    return true;
  };

  onMount(async () => {
    if (params.id) {
      await getFactoringCompanyDetailsDocuments(params.id);
    }
  });

  return (
    <Card
      startTitle="Upload Documents"
      loading={factoringCompanyDetailsStore.isDocumentsLoading}
    >
      <CommonFileUpload
        height={1}
        maxFileSize={12582912}
        uploadedFiles={factoringCompanyDetailsStore.documents}
        shouldUploadOnSelect
        isRemoveFile
        apiCallBackFn={handleFileUpload}
        removeFile={handleFileRemove}
        customValidation={customValidation}
        isAdmin={isFullAdmin()}
      />
    </Card>
  );
};
