import {
  apiClient,
  v3Client,
  v3FileUploadClient,
  v3ODataClient,
} from '@api/apiClient';
import { logError } from '@utils/errorLogging';
import { createError } from '@utils/errorUtils';
import { Comments } from '@store/orders';
import { V4ApiResponse } from '@store/customers/customerDetails';
import { handleToast } from '@utils/utils';
import { ToastType } from '@components';

import { ProspectData, ProspectGridApiResponse } from './types';

export const getDoNotSolicitCustomers = async () => {
  try {
    const response = await v3Client.get('/Customer/GetDoNotSolicitCustomers');
    return response as unknown as string[];
  } catch (error) {
    logError('sendEDIUpdate', error as Error);
    throw createError(error, 'Failed to send EDI Update');
  }
};

export const getProspectsGridData = async (params: Record<string, string>) => {
  try {
    const response: ProspectGridApiResponse = await v3ODataClient.get(
      'Prospects',
      {
        params,
      },
    );
    return response;
  } catch (error) {
    logError('getProspectsGridData', error as Error);
    throw createError(error, 'Failed to get prospects grid data');
  }
};

export const updateProspectStatus = async (params: {
  id: number;
  status: 'Quoting' | 'Contacted' | 'Lead' | 'Dead Lead';
}) => {
  try {
    const response: {
      success: boolean;
    } = await v3Client.get(`/prospect/status/${params.id}/${params.status}`, {
      params: {
        Id: params.id,
        Status: params.status,
      },
    });
    return response.success;
  } catch (error) {
    logError('updateProspectStatus', error as Error);
    throw createError(error, 'Failed to update prospect status');
  }
};

export const getCustomerLookup = async (params: {
  name?: string;
  state?: string;
}) => {
  try {
    const response: {
      name: string;
      status: string;
    }[] = await v3Client.get('/Customer/Lookup', {
      params,
    });
    return response;
  } catch (error) {
    logError('getCustomerLookup', error as Error);
    throw createError(error, 'Failed to get customer lookup');
  }
};

export const fetchProspectDetails = async (id: number) => {
  try {
    const response: V4ApiResponse<ProspectData> = await apiClient.get(
      `ProspectCustomer/${id}`,
    );
    return response;
  } catch (error) {
    const err = createError(error, 'Failed to get prospect details');
    handleToast(ToastType.Error, err.message);
  }
};

export const fetchUploadedDocuments = async (id: number) => {
  try {
    const response: {
      name: string;
      url: string;
    }[] = await v3Client.get(`/Document/Prospect/${id}`);
    return response;
  } catch (error) {
    logError('getUploadedDocuments', error as Error);
    throw createError(error, 'Failed to get uploaded documents');
  }
};

export const uploadProspectDocument = async (id: number, file: File) => {
  try {
    const formData = new FormData();
    formData.append(`${file.name}`, file);
    const response = await v3FileUploadClient.post(
      `/Document/Prospect/${id}`,
      formData,
    );
    return response as unknown as {
      success: boolean;
      data: {
        url: string;
      };
      message: string | null;
    };
  } catch (error) {
    logError('uploadProspectDocument', error as Error);
    throw createError(error, 'Failed to upload prospect document');
  }
};

export const deleteProspectDocument = async (payload: FormData) => {
  try {
    const urlEncodedData = new URLSearchParams();
    for (const pair of payload.entries()) {
      urlEncodedData.append(pair[0], String(pair[1]));
    }
    const response = await v3FileUploadClient.post(
      '/Document/DeleteFile',
      urlEncodedData,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
      },
    );

    return response as unknown as {
      success: boolean;
      message: string | null;
    };
  } catch (error) {
    logError('deleteProspectDocument', error as Error);
    throw createError(error, 'Failed to delete prospect document');
  }
};

export const postProspectComment = async (payload: {
  id: number;
  comment: string;
  commentType: string;
  type: string;
}) => {
  try {
    const response: {
      success: boolean;
      message: string | null;
      data: Comments;
    } = await v3Client.post(`/Comment/Prospect/${payload.id}`, payload);
    return response.data;
  } catch (error) {
    logError('postProspectComment', error as Error);
    throw createError(error, 'Failed to post prospect comment');
  }
};

export const deleteProspectComment = async (id: number) => {
  try {
    const response: {
      success: boolean;
      message: string | null;
    } = await v3Client.delete(`/Comment/Prospect/${id}`);
    return response.success;
  } catch (error) {
    logError('deleteProspectComment', error as Error);
    throw createError(error, 'Failed to delete prospect comment');
  }
};

export const updateProspectDetailsApi = async (payload: ProspectData) => {
  try {
    const response: V4ApiResponse<ProspectData> = await apiClient.put(
      '/ProspectCustomer',
      payload,
    );
    return response;
  } catch (error) {
    const err = createError(error, 'Failed to get prospect details');
    handleToast(ToastType.Error, err.message);
  }
};

export const bulkImportProspects = async (file: File) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response: {
      success: true;
      data: {
        imported: [];
        notImported: [];
      };
      message: null;
    } = await v3FileUploadClient.post('/Prospect/Import', formData);
    return response;
  } catch (error) {
    logError('bulkImportProspects', error as Error);
    throw createError(error, 'Failed to bulk import prospects');
  }
};
