import {
  fetchLtlReferenceNumbers,
  getQuotes,
  getSavedQuotes,
  LtlReferenceNumberUpdate,
  fetchQuoteParameters,
  fetchQuote,
} from '@store/ltl/services';
import { createStore, produce } from 'solid-js/store';
import { LtlQuoteParametersViewModel, LtlQuoteViewModel } from '@store/orders';

import {
  AccessorialState,
  LoadItemCalculationState,
  LoadItemCalculationTotals,
  LTLLoadItem,
  LTLQuote,
  LTLQuoteState,
  LTLQuotingResultState,
  LTLRateQuoteRequestState,
  LTLReferenceNumberTypes,
  LTLTrackingData,
  LTLTrackingDataState,
  SavedQuotesState,
} from './types';
import { LTLRateQuoteRequest } from './v3types';

const [savedQuoteStore, setSavedQuoteStore] = createStore<SavedQuotesState>({
  isLoading: true,
  isError: false,
  errorMessage: '',
  items: [],
  overlay: false,
});

const [accessorialStore, setAccessorialStore] = createStore<AccessorialState>({
  isLoading: true,
  isError: false,
  errorMessage: '',
  items: [],
  overlay: false,
});

const [ltlQuotingResultStore, setLTLQuotingResultStore] =
  createStore<LTLQuotingResultState>({
    isLoading: false,
    isError: false,
    errorMessage: '',
    items: [],
    overlay: false,
  });

const [ltlRateQuoteRequest, setLTLRateQuoteRequest] =
  createStore<LTLRateQuoteRequestState>({
    isLoading: true,
    isError: false,
    errorMessage: '',
    items: {} as LTLRateQuoteRequest,
    overlay: false,
  });

const [ltlQuoteState, setLtlQuoteState] = createStore<LTLQuoteState>({
  isLoading: false,
  isError: false,
  errorMessage: '',
  quote: undefined,
  overlay: false,
  falveyPricingQuote: undefined,
  declaredValue: undefined,
});

const [loadItemCalculationState, setLoadItemCalculationState] =
  createStore<LoadItemCalculationState>({
    isLoading: false,
    isError: false,
    errorMessage: '',
    items: {
      totalLinearFeet: 0,
      totalCubicFeet: 0,
      loadItemCalculations: [],
    } as LoadItemCalculationTotals,
    overlay: false,
  });

const [ltlReferenceNumberStore, setLtlReferenceNumberStore] =
  createStore<LTLTrackingDataState>({
    isLoading: false,
    isError: false,
    errorMessage: '',
    trackingData: {
      referenceNumbers: [],
      trackingUrlTemplate: '',
      loadId: 0,
    },
  });

const [quoteParametersStore, setQuoteParametersStore] = createStore({
  isLoading: false,
  isError: false,
  errorMessage: '',
  quoteParameters: [] as LtlQuoteParametersViewModel[],
});

const loadQuoteParameters = async (isHistory: boolean) => {
  setQuoteParametersStore({
    isLoading: true,
    isError: false,
    errorMessage: '',
  });

  try {
    const data = await fetchQuoteParameters(isHistory);
    setQuoteParametersStore(
      produce((draft) => {
        draft.quoteParameters = data;
        draft.isLoading = false;
      }),
    );
  } catch (error) {
    setQuoteParametersStore({
      isError: true,
      errorMessage: 'Failed to load quote parameters',
      isLoading: false,
    });
  }
};

const [quoteStore, setQuoteStore] = createStore({
  isLoading: false,
  isError: false,
  errorMessage: '',
  quotes: [] as LtlQuoteViewModel[],
});

const loadQuote = async (quoteParametersId: number) => {
  setQuoteStore({
    isLoading: true,
    isError: false,
    errorMessage: '',
  });

  try {
    const data = await fetchQuote(quoteParametersId);
    setQuoteStore(
      produce((draft) => {
        draft.quotes = data;
        draft.isLoading = false;
      }),
    );
  } catch (error) {
    setQuoteStore({
      isError: true,
      errorMessage: 'Failed to load quote',
      isLoading: false,
    });
  }
};

// @ts-expect-error stuff
window.ltlQuote = { ltlQuoteState, setLtlQuoteState };

const setLoadItemCalculation = (
  loadItemCalculation: LoadItemCalculationTotals,
) => {
  try {
    setLoadItemCalculationState(
      produce((draft: LoadItemCalculationState) => {
        draft.items = loadItemCalculation;
        draft.isLoading = false;
      }),
    );
  } catch (e) {
    setLoadItemCalculationState(
      produce((draft: LoadItemCalculationState) => {
        draft.isError = true;
        draft.errorMessage = 'Failed loading load item calculation';
        draft.isLoading = false;
      }),
    );
  } finally {
    setLoadItemCalculationState('isLoading', false);
  }
};

const setLTLQuote = (ltlQuote: LTLQuote) => {
  setLtlQuoteState(
    produce((draft: LTLQuoteState) => {
      draft.quote = ltlQuote;
    }),
  );
};

const setCustomerInfo = (customerId: number, customerName: string) => {
  setLtlQuoteState(
    produce((draft: LTLQuoteState) => {
      if (draft.quote) {
        draft.quote.customerId = customerId;
        draft.quote.customerName = customerName;
      }
    }),
  );
};

const setOriginOrDestinationInfo = (info: {
  type: 'Origin' | 'Destination';
  city?: string | null;
  state?: string | null;
  zip: string;
  country?: string | null;
}) => {
  setLtlQuoteState(
    produce((draft: LTLQuoteState) => {
      if (draft.quote) {
        if (info.type === 'Origin') {
          draft.quote.originCity = info.city;
          draft.quote.originState = info.state;
          draft.quote.originZip = info.zip;
          draft.quote.originCountry = info.country;
        } else {
          draft.quote.destinationCity = info.city;
          draft.quote.destinationState = info.state;
          draft.quote.destinationZip = info.zip;
          draft.quote.destinationCountry = info.country;
        }
      }
    }),
  );
};

const setLoadItems = (items: LTLLoadItem[]) => {
  setLtlQuoteState(
    produce((draft: LTLQuoteState) => {
      if (draft.quote) {
        draft.quote.loadItems = items;
      }
    }),
  );
};

const setPickupDate = (date: Date | string) => {
  setLtlQuoteState(
    produce((draft: LTLQuoteState) => {
      if (draft.quote) {
        draft.quote.pickupDate = date;
      }
    }),
  );
};

const fetchSavedQuotes = async () => {
  try {
    setSavedQuoteStore('isLoading', true);
    const resp = await getSavedQuotes();
    setSavedQuoteStore(
      produce((draft: SavedQuotesState) => {
        draft.items = resp;
        draft.isLoading = false;
      }),
    );
  } catch (e) {
    setSavedQuoteStore(
      produce((draft: SavedQuotesState) => {
        draft.isError = true;
        draft.errorMessage = 'Failed loading saved quotes';
        draft.isLoading = false;
      }),
    );
  } finally {
    setSavedQuoteStore('isLoading', false);
  }
};

const fetchQuotes = async (request: LTLRateQuoteRequest) => {
  try {
    setLTLQuotingResultStore('isLoading', true);
    const resp = await getQuotes(request);
    if (Array.isArray(resp) && resp.length > 0) {
      setLTLQuotingResultStore(
        produce((draft: LTLQuotingResultState) => {
          draft.isLoading = false;
          draft.items = resp;
          draft.customerId = request.customerId ?? undefined;
          draft.isError = false;
        }),
      );
      setLTLRateQuoteRequest(
        produce((draft: LTLRateQuoteRequestState) => {
          draft.isLoading = false;
          draft.items = request;
        }),
      );
    } else {
      setLTLQuotingResultStore(
        produce((draft: LTLQuotingResultState) => {
          draft.isLoading = false;
          draft.items = [];
          draft.isError = true;
        }),
      );
    }
  } catch (e) {
    setLTLQuotingResultStore(
      produce((draft: LTLQuotingResultState) => {
        draft.isLoading = false;
        draft.isError = true;
        draft.errorMessage = 'Failed loading quotes';
      }),
    );
  }
};

const fetchReferenceNumbers = async (loadId: number) => {
  try {
    setLtlReferenceNumberStore('isLoading', true);
    const resp = (await fetchLtlReferenceNumbers(loadId)) as LTLTrackingData;
    if (typeof resp === 'object') {
      setLtlReferenceNumberStore(
        produce((draft: LTLTrackingDataState) => {
          draft.trackingData = {
            referenceNumbers: resp.referenceNumbers,
            trackingUrlTemplate: resp.trackingUrlTemplate,
            loadId: loadId,
          };
        }),
      );
    }
  } catch (e) {
    setLtlReferenceNumberStore(
      produce((draft: LTLTrackingDataState) => {
        draft.isError = true;
        draft.errorMessage = 'Failed loading reference numbers';
      }),
    );
  } finally {
    setLtlReferenceNumberStore('isLoading', false);
  }
};

const updateReferenceNumberStore = (
  type: LTLReferenceNumberTypes,
  value: string,
) => {
  setLtlReferenceNumberStore(
    produce((draft: LTLTrackingDataState) => {
      draft.trackingData = {
        ...ltlReferenceNumberStore.trackingData,
        referenceNumbers:
          ltlReferenceNumberStore.trackingData.referenceNumbers.map(
            (refNumber) =>
              refNumber.type === type
                ? {
                    ...refNumber,
                    value,
                  }
                : refNumber,
          ),
      };
    }),
  );
};

const saveReferenceNumbers = async () => {
  try {
    await LtlReferenceNumberUpdate(
      ltlReferenceNumberStore.trackingData.loadId,
      ltlReferenceNumberStore.trackingData.referenceNumbers.filter(
        (referenceNumber) =>
          referenceNumber.type !== LTLReferenceNumberTypes.BOL &&
          referenceNumber.value.trim() !== '',
      ),
    );
  } catch (e) {
    setLtlReferenceNumberStore(
      produce((draft: LTLTrackingDataState) => {
        draft.isError = true;
        draft.errorMessage = 'Failed saving reference numbers';
      }),
    );
  }
};

const clearLtlQuoteResults = () => {
  setLTLQuotingResultStore(
    produce((draft: LTLQuotingResultState) => {
      draft.items = [];
    }),
  );
};

export {
  setLoadItemCalculation,
  accessorialStore,
  ltlQuoteState,
  ltlQuotingResultStore,
  ltlRateQuoteRequest,
  savedQuoteStore,
  ltlReferenceNumberStore,
  loadItemCalculationState,
  setAccessorialStore,
  setLtlQuoteState,
  setLTLQuotingResultStore,
  setLTLRateQuoteRequest,
  setSavedQuoteStore,
  setLtlReferenceNumberStore,
  setLTLQuote,
  setCustomerInfo,
  setOriginOrDestinationInfo,
  setLoadItems,
  setPickupDate,
  fetchSavedQuotes,
  fetchQuotes,
  fetchReferenceNumbers,
  updateReferenceNumberStore,
  saveReferenceNumbers,
  clearLtlQuoteResults,
  quoteParametersStore,
  loadQuoteParameters,
  quoteStore,
  loadQuote,
};
