import { NewAutoComplete, ToastType } from '@components';
import { setCustomerInfo } from '@store/ltl/store';
import {
  customerStore,
  loadCustomerInfo,
  orderStore,
  setCustomerStore,
  updateLoadPropertyAtIndex,
  updateOrderState,
  validateFieldForOrder,
} from '@store/orders';
import { defaultCustomerDetails } from '@store/orders/defaultVals';
import { Info } from '@suid/icons-material';
import { Box } from '@suid/material';
import { customerEditable, handleToast, printError } from '@utils/utils';
import { Show } from 'solid-js';
import { ICustomerContact } from '@typeDefinitions/contactTypes';

import classes from './classes';

type Props = {
  setCustomerWarnings: (warnings: string[]) => void;
};

const CustomerSelection = (props: Props) => {
  const cls = classes.customerInfo;

  const handleSelect = async (item: ICustomerContact) => {
    try {
      await loadCustomerInfo(item.id);
    } catch (error) {
      printError(error);
      handleToast(ToastType.Error, 'Unable to load customer info');
    }

    updateOrderState({ customerId: item.id });
    setCustomerInfo(item.id, item.name);
    if (orderStore.order.loads.length > 0) {
      updateLoadPropertyAtIndex({ customerId: item.id });
    }
    await validateFieldForOrder('customerId', 'customerId');
  };

  return (
    <Box class="flex gap-4 my-[16px] items-center" id="instructions-and-costs">
      <Box class={cls.customerContainer}>
        {orderStore.order.loads.some((x) => x.hideCustomerName) ? (
          <span class={cls.customerFieldInfo}>
            {orderStore.order.customer?.Name}
          </span>
        ) : (
          <NewAutoComplete
            id="autocomplete-customer-info"
            endpoint="customer/autocomplete?term"
            onItemSelect={(item) => handleSelect(item as ICustomerContact)}
            defaultValue={customerStore.customer.name}
            label="Customer Name"
            size="small"
            error={
              orderStore.orderFormError &&
              orderStore.orderFormError['customerId']
            }
            onClearValue={() => {
              updateOrderState({
                customerId: null,
                customerContactId: undefined,
                owner: undefined,
                metadata: undefined,
              });
              setCustomerStore({
                customer: defaultCustomerDetails,
                loading: false,
              });
              props.setCustomerWarnings([]);
            }}
            disabled={
              orderStore.order.hideCustomerName ||
              !customerEditable() ||
              orderStore.isReadOnly
            }
          />
        )}
        <Show when={customerStore.customer.thirdParty}>
          <span class={cls.customerFieldInfo}>
            <Info style={cls.infoIconStyle} />
            This customer has 3rd Party Billing
          </span>
        </Show>
      </Box>
    </Box>
  );
};
export default CustomerSelection;
