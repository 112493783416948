import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { AdvancedFilterModule } from '@ag-grid-enterprise/advanced-filter';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { V3MessageData } from '@common/types';
import { SupportModal } from '@components/SupportModal/SupportModal';
import { datadogRum } from '@datadog/browser-rum';
import { Route, Router } from '@solidjs/router';
import { initializeFeatureFlags } from '@store/featureFlags/store';
import { checkUsersPostingValid, createSocket } from '@store/Global/service';
import { setSocketConnection } from '@store/Global/store';
import { openModal } from '@store/modals';
import { userStore } from '@store/user';
import { ThemeProvider } from '@suid/material';
import { ConfigManager } from '@utils/ConfigManager';
import { EquipmentSearchRoute } from '@views/equipmentSearch';
import { SavedQuoteRoute } from '@views/ltl';
import { onMount, Show } from 'solid-js';
import { Toaster } from 'solid-toast';

import theme from './utils/theme';
import {
  AdminToolsRoute,
  CarrierDetailsRoute,
  CarrierLaneSearchRoute,
  CarrierMatchingOffersRoute,
  CarrierMatchingRoute,
  CarrierPlaylists,
  CarrierRoute,
  ClaimRoute,
  ClaimsTabRoute,
  CollectionDetailsRoute,
  CollectionsDashboardRoute,
  CollectionsRoute,
  CustomerDetailsRoute,
  CustomersRoute,
  DocumentsPageRoute,
  DormantAccountsRoute,
  EditHistoryRoute,
  FactoringCompanyDetailsCreationRoute,
  FactoringCompanyDetailsRoute,
  FactoringCompanyRoute,
  HoldsTabRoute,
  HomeRoute,
  LoadboardRoute,
  LoadSearchRoute,
  LoadTendersRoute,
  LTLRoute,
  NewCarrierDetailsRoute,
  NewCustomerRoute,
  NewProspectRoute,
  OfficeDetailRoute,
  OfficeRoute,
  OldLoadsRoute,
  OrderDetailsRoute,
  OrderRoute,
  PostingGroupRoute,
  RevenueDashboardRoute,
  RevenueSearchRoute,
  SendCarrierPacketRoute,
  SettlementDashboardRoute,
  UserDetailsRoute,
  ProspectRoute,
  ProspectDetailsRoute,
} from './views';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';

LicenseManager.setLicenseKey(ConfigManager.agGridLicensePicks);
// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
ModuleRegistry.registerModules([
  SetFilterModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  RowGroupingModule,
  SideBarModule,
  ClientSideRowModelModule,
  MultiFilterModule,
  AdvancedFilterModule,
  ClipboardModule,
  MenuModule,
  ExcelExportModule,
  MenuModule,
  ServerSideRowModelModule,
]);
// Initialize Datadog Session ID
const rumContext = datadogRum.getInternalContext();
let rumSessionId: string | undefined;
if (rumContext && Boolean(rumContext.session_id)) {
  rumSessionId = rumContext.session_id;
}

// App Component
function App() {
  onMount(async () => {
    const socketv = await createSocket({
      connection: `${ConfigManager.apiUrl}/clientHub`,
      loginUrl: `${ConfigManager.apiUrl}/api/login/token`,
    });
    const handleV3Message = (event: MessageEvent<V3MessageData>) => {
      if (event.data.type === 'supportTicket') {
        openModal('SupportModal');
      }
    };
    window.addEventListener('message', handleV3Message);
    await userStore.userPromise;
    await checkUsersPostingValid();
    setSocketConnection(socketv);
    datadogRum.setUser({
      id: `${userStore.user.id}`,
      name: `${userStore.user.name}`,
    });

    await initializeFeatureFlags();
  });

  return (
    <ThemeProvider theme={theme}>
      <Toaster />
      <div class="relative">
        <SupportModal />
        <Router>
          <Route
            path={SettlementDashboardRoute.path}
            component={SettlementDashboardRoute.component}
          />
          <Route
            path={RevenueDashboardRoute.path}
            component={RevenueDashboardRoute.component}
          />
          <Route path={ClaimRoute.path} component={ClaimRoute.component} />
          <Route
            path={UserDetailsRoute.path}
            component={UserDetailsRoute.component}
          />
          <Route
            path={AdminToolsRoute.path}
            component={AdminToolsRoute.component}
          />
          <Route
            path={PostingGroupRoute.path}
            component={PostingGroupRoute.component}
          />
          <Route path={HomeRoute.path} component={HomeRoute.component} />
          <Route path={CarrierRoute.path} component={CarrierRoute.component} />
          <Route path={OrderRoute.path} component={OrderRoute.component} />
          <Route
            path={LoadboardRoute.path}
            component={LoadboardRoute.component}
          />
          <Route
            path={DormantAccountsRoute.path}
            component={DormantAccountsRoute.component}
          />
          <Route
            path={SavedQuoteRoute.path}
            component={SavedQuoteRoute.component}
          />
          <Route
            path={OrderDetailsRoute.path}
            component={OrderDetailsRoute.component}
          />
          <Route
            path={EquipmentSearchRoute.path}
            component={EquipmentSearchRoute.component}
          />
          <Route
            path={CarrierMatchingRoute.path}
            component={CarrierMatchingRoute.component}
          />
          <Route
            path={CarrierMatchingOffersRoute.pathRep}
            component={() => {
              return <CarrierMatchingOffersRoute.component dataType="rep" />;
            }}
          />
          <Route
            path={CarrierMatchingOffersRoute.pathManager}
            component={() => {
              return (
                <CarrierMatchingOffersRoute.component dataType="manager" />
              );
            }}
          />
          <Route path={LTLRoute.path} component={LTLRoute.component} />
          <Route
            path={ClaimsTabRoute.path}
            component={ClaimsTabRoute.component}
          />
          <Route
            path={HoldsTabRoute.path}
            component={HoldsTabRoute.component}
          />
          <Route path={OfficeRoute.path} component={OfficeRoute.component} />
          <Route
            path={OfficeDetailRoute.path}
            component={OfficeDetailRoute.component}
          />
          <Route
            path={CustomersRoute.path}
            component={CustomersRoute.component}
          />
          <Route
            path={OldLoadsRoute.path}
            component={OldLoadsRoute.component}
          />
          <Route
            path={EditHistoryRoute.path}
            component={EditHistoryRoute.component}
          />
          <Route
            path={CarrierLaneSearchRoute.path}
            component={CarrierLaneSearchRoute.component}
          />
          <Route
            path={CustomerDetailsRoute.path}
            component={CustomerDetailsRoute.component}
          />
          <Route
            path={NewCustomerRoute.path}
            component={NewCustomerRoute.component}
          />
          <Route
            path={CarrierPlaylists.path}
            component={CarrierPlaylists.component}
          />
          <Route
            path={CarrierDetailsRoute.path}
            component={CarrierDetailsRoute.component}
          />
          <Route
            path={DocumentsPageRoute.path}
            component={DocumentsPageRoute.component}
          />
          <Route
            path={LoadSearchRoute.path}
            component={LoadSearchRoute.component}
          />
          <Route
            path={FactoringCompanyRoute.path}
            component={FactoringCompanyRoute.component}
          />
          <Route
            path={FactoringCompanyDetailsRoute.path}
            component={FactoringCompanyDetailsRoute.component}
          />
          <Route
            path={FactoringCompanyDetailsCreationRoute.path}
            component={FactoringCompanyDetailsCreationRoute.component}
          />
          <Route
            path={NewProspectRoute.path}
            component={NewProspectRoute.component}
          />
          <Route
            path={CollectionsRoute.path}
            component={CollectionsRoute.component}
          />
          <Route
            path={CollectionsDashboardRoute.path}
            component={CollectionsDashboardRoute.component}
          />
          <Route
            path={NewCarrierDetailsRoute.path}
            component={NewCarrierDetailsRoute.component}
          />
          <Route
            path={CollectionDetailsRoute.path}
            component={CollectionDetailsRoute.component}
          />
          <Route
            path={RevenueSearchRoute.path}
            component={RevenueSearchRoute.component}
          />
          <Route
            path={LoadTendersRoute.path}
            component={LoadTendersRoute.component}
          />
          <Route
            path={SendCarrierPacketRoute.path}
            component={SendCarrierPacketRoute.component}
          />
          <Show when={Boolean(rumSessionId)}>
            <span class="absolute right-6 -bottom-6 bg-[#0000003D] text-white text-[10px] font-medium p-1 rounded-sm leading-3">
              {rumSessionId}
            </span>
          </Show>
          <Route
            path={ProspectRoute.path}
            component={ProspectRoute.component}
          />
          <Route
            path={ProspectDetailsRoute.path}
            component={ProspectDetailsRoute.component}
          />
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
