import { DisabledTracking, PingLocation, ResendInstallationReq } from '@assets';
import { FlagType } from '@store/orders';

export const ADD_PLAYLIST_ID = 'addPlaylist';
export const RATE_CARRIER_ID = 'rateCarrier';
export const LOAD_TRACKING_ID = 'loadTracking';
export const ASSIGN_LOAD_ID = 'assignLoad';
export const CARRIER_INSTRUCTION_MODAL_ID = 'carrierInstructionModal';
export const EDI_LOGS_ID = 'ediLogs';
export const EDI_LOAD_TENDER_ID = 'ediLoadTenderModal';
export const PAY_WITH_CREDIT_CARD_ID = 'payWithCreditCardModal';
export const UNLINKED_EDI_TENDER_ID = 'unlinkedEDITender';
export const ACCEPT_TRACKING_CHARGES_ID = 'acceptTrackingCharges';
export const TRUCK_SEARCH_ID = 'truckSearch';
export const CARRIER_DETAILS_VENDOR_HOLD_MODAL_ID =
  'carrierDetailsVendorHoldModal';
export const DOCUMEN_PAGE_MODAL_ADD_EDIT_ID = 'documentPageModalAddEdit';
export const NEARBY_CARRIER_MODAL_ID = 'nearbyCarrierModal';
export const IMPORT_PROSPECT_MODAL_ID = 'importProspectModal';

export const ADD_BILLING_HOLD_NOTE = 'addBillingHoldNote';
export const EDIT_BILLING_HOLD_AND_NOTE = 'editBillingNote';
export const ADD_EDIT_CARRIER_DETAILS_CONTACT_MODAL_ID =
  'addEditCarrierDetailsContactModal';
export const CARRIER_DETAILS_CARRIER_REVIEWS_MODAL_ID =
  'carrierDetailsCarrierReviewsModal';
export const CARRIER_DETAILS_TRANSFLOW_MODAL_ID =
  'carrierDetailsTransflowModal';
export const IS_LTL_LOAD_MODAL_ID = 'IS_LTL_LOAD';

export const DELETE_COMMENT_DIALOG_ID = 'deleteCommentDialog';
export const DELETE_FACTORING_COMPANY_DIALOG_ID =
  'deleteFactoringCompanyDialog';

export const loadStatusItems = [
  { label: 'Can Get', value: 'Can Get' },
  { label: 'Unassigned', value: 'Unassigned' },
  { label: 'Assigned', value: 'Assigned' },
  { label: 'Dispatched', value: 'Dispatched' },
  { label: 'At Shipper', value: 'At Shipper' },
  { label: 'Loaded', value: 'Loaded' },
  { label: 'In Transit', value: 'In Transit' },
  { label: 'Out On Delivery', value: 'Out On Delivery' },
  { label: 'At Receiver', value: 'At Receiver' },
  { label: 'Empty', value: 'Empty' },
  // { label: 'Paid', value: 'Paid', disabled: true },
  // { label: 'Void', value: 'Void', disabled: true },
];

export const flagsThatDontRequireDesc = [
  FlagType.Blue,
  FlagType.Yellow,
  FlagType.Green,
  FlagType.Orange,
];

export const trackingOptions = [
  {
    label: 'Resend Installation Request',
    value: 'Resend',
    icon: ResendInstallationReq,
  },
  { label: 'Ping Location', value: 'PingLocation', icon: PingLocation },
  { label: 'Disable Tracking', value: 'Disable', icon: DisabledTracking },
];

export const fuelCharges = [
  'Spot Charge',
  'Line Haul',
  'Rate per Mile',
  'Rate per Pound',
  'Rate per Unit',
];

export const lineItemsTypesForCarrierPay = [
  {
    label: 'Pricing',
    value: 'Pricing',
    disabled: true,
  },
  { label: 'Line Haul', value: 'Line Haul' },
  { label: 'Rate per Mile', value: 'Rate per Mile' },
  { label: 'Rate per Pound', value: 'Rate per Pound' },
  { label: 'Rate per Unit', value: 'Rate per Unit' },
  {
    label: 'Accessorials',
    value: 'Accessorials',
    disabled: true,
  },
  { label: 'Accessorial', value: 'Accessorial' },
  { label: 'Bucket Truck', value: 'Bucket Truck' },
  { label: 'Damage', value: 'Damage' },
  { label: 'Dead Head', value: 'Dead Head' },
  { label: 'Detention', value: 'Detention' },
  { label: 'Detention Loading', value: 'Detention Loading' },
  { label: 'Detention Unloading', value: 'Detention Unloading' },
  { label: 'Driver Assist', value: 'Driver Assist' },
  { label: 'Fuel', value: 'Fuel' },
  { label: 'Fumigation', value: 'Fumigation' },
  { label: 'Layover', value: 'Layover' },
  { label: 'Lumper', value: 'Lumper' },
  { label: 'Lumper Loading', value: 'Lumper Loading' },
  { label: 'Lumper Unloading', value: 'Lumper Unloading' },
  { label: 'Out of Route Miles', value: 'Out of Route Miles' },
  { label: 'Positioning', value: 'Positioning' },
  { label: 'Protective Services', value: 'Protective Services' },
  { label: 'Re-Delivery', value: 'Re-Delivery' },
  { label: 'Route Survey', value: 'Route Survey' },
  { label: 'Storage', value: 'Storage' },
  { label: 'Team Service', value: 'Team Service' },
  { label: 'TONU', value: 'TONU' },
  {
    label: 'Fees',
    value: 'Fees',
    disabled: true,
  },
  { label: 'Bonus', value: 'Bonus' },
  { label: 'Check Call', value: 'Check Call' },
  { label: 'Drop Trailer', value: 'Drop Trailer' },
  { label: 'Fee', value: 'Fee' },
  { label: 'Fine', value: 'Fine' },
  { label: 'GPS Tracking', value: 'GPS Tracking' },
  { label: 'Interstate/Highway Toll', value: 'Interstate/Highway Toll' },
  { label: 'Late Fee', value: 'Late Fee' },
  { label: 'New York Delivery Charge', value: 'New York Delivery Charge' },
  { label: 'On-time Delivery', value: 'On-time Delivery' },
  { label: 'On-time Pick Up', value: 'On-time Pick Up' },
  { label: 'Over Weight', value: 'Over Weight' },
  { label: 'Pallet Charge', value: 'Pallet Charge' },
  { label: 'Premium Charge', value: 'Premium Charge' },
  { label: 'Special Handling Service', value: 'Special Handling Service' },
  { label: 'Tarp Fee', value: 'Tarp Fee' },
  { label: 'Unloading', value: 'Unloading' },
  {
    label: 'Intermodal',
    value: 'Intermodal',
    disabled: true,
  },
  { label: 'Block And Brace', value: 'Block And Brace' },
  { label: 'Chassis', value: 'Chassis' },
  { label: 'Diversion', value: 'Diversion' },
  { label: 'Driver Assist', value: 'Driver Assist' },
  { label: 'Driver Count', value: 'Driver Count' },
  { label: 'Driver Dunnage Disposal', value: 'Driver Dunnage Disposal' },
  { label: 'Driver Layover', value: 'Driver Layover' },
  { label: 'Driver Sweep', value: 'Driver Sweep' },
  { label: 'Driver Wash Out', value: 'Driver Wash Out' },
  {
    label: 'Dry Run (TONU) Equipment Not Used',
    value: 'Dry Run (TONU) Equipment Not Used',
  },
  { label: 'Hazmat', value: 'Hazmat' },
  { label: 'High Value Seal Charge', value: 'High Value Seal Charge' },
  { label: 'Lumper Loading', value: 'Lumper Loading' },
  { label: 'Lumper Unloading', value: 'Lumper Unloading' },
  { label: 'Miscellaneous', value: 'Miscellaneous' },
  { label: 'Night Delivery', value: 'Night Delivery' },
  { label: 'Pier Pass', value: 'Pier Pass' },
  {
    label: 'Power Detention Live Unload',
    value: 'Power Detention Live Unload',
  },
  {
    label: 'Power Detention Drop and Hook',
    value: 'Power Detention Drop and Hook',
  },
  { label: 'Pre-Pull', value: 'Pre-Pull' },
  { label: 'Reconsignment', value: 'Reconsignment' },
  { label: 'Scale Light and Heavy', value: 'Scale Light and Heavy' },
  { label: 'Single Scale Charge', value: 'Single Scale Charge' },
  { label: 'Stop Off', value: 'Stop Off' },
  { label: 'Tri-Axle', value: 'Tri-Axle' },
  { label: 'Yard Pull', value: 'Yard Pull' },
  { label: 'Yard Storage', value: 'Yard Storage' },
  {
    label: 'LTL',
    value: 'LTL',
    disabled: true,
  },
  { label: 'Additional Freight', value: 'Additional Freight' },
  { label: 'Adjustment', value: 'Adjustment' },
  { label: 'Agent Authorized SED', value: 'Agent Authorized SED' },
  { label: 'Airport Delivery', value: 'Airport Delivery' },
  { label: 'Airport Pickup', value: 'Airport Pickup' },
  { label: 'Appointment Delivery', value: 'Appointment Delivery' },
  { label: 'Appointment Pickup', value: 'Appointment Pickup' },
  { label: 'Bill of Lading', value: 'Bill of Lading' },
  { label: 'Blind Shipment', value: 'Blind Shipment' },
  { label: 'CarrierCharge', value: 'CarrierCharge' },
  { label: 'CFS Delivery', value: 'CFS Delivery' },
  { label: 'CFS Pickup', value: 'CFS Pickup' },
  { label: 'Clean Air Surcharge', value: 'Clean Air Surcharge' },
  { label: 'COI Required', value: 'COI Required' },
  { label: 'Correction of Terms', value: 'Correction of Terms' },
  { label: 'Cubic Capacity', value: 'Cubic Capacity' },
  { label: 'Dimensional Weight', value: 'Dimensional Weight' },
  { label: 'Discount', value: 'Discount' },
  { label: 'Gate Fee', value: 'Gate Fee' },
  { label: 'Ground Delivery Service', value: 'Ground Delivery Service' },
  { label: 'Guaranteed Delivery Day', value: 'Guaranteed Delivery Day' },
  { label: 'GST/HST', value: 'GST/HST' },
  { label: 'Handling Fee', value: 'Handling Fee' },
  { label: 'High Cost Area Fee', value: 'High Cost Area Fee' },
  { label: 'Inspection Fee', value: 'Inspection Fee' },
  { label: 'Inside Delivery', value: 'Inside Delivery' },
  { label: 'Inside Pickup', value: 'Inside Pickup' },
  { label: 'Interstate/Highway Toll', value: 'Interstate/Highway Toll' },
  { label: 'Labeling', value: 'Labeling' },
  { label: 'Labor', value: 'Labor' },
  { label: 'Liftgate Delivery', value: 'Liftgate Delivery' },
  { label: 'Liftgate Pickup', value: 'Liftgate Pickup' },
  { label: 'Limited Access Delivery', value: 'Limited Access Delivery' },
  { label: 'Limited Access Pickup', value: 'Limited Access Pickup' },
  { label: 'LOA', value: 'LOA' },
  { label: 'Misc', value: 'Misc', disabled: true },
  { label: 'Misc', value: 'Misc' },
  { label: 'New York Delivery Charge', value: 'New York Delivery Charge' },
  { label: 'Notification Delivery', value: 'Notification Delivery' },
  { label: 'Over Length 12 < 16', value: 'Over Length 12 < 16' },
  { label: 'Over Length 16 < 20', value: 'Over Length 16 < 20' },
  { label: 'Over Length 20 < 28', value: 'Over Length 20 < 28' },
  { label: 'Over Length 8 < 12', value: 'Over Length 8 < 12' },
  { label: 'Over Length > 28', value: 'Over Length > 28' },
  { label: 'Paid Wrong Carrier', value: 'Paid Wrong Carrier' },
  { label: 'Premium Charge', value: 'Premium Charge' },
  { label: 'Protect From Freeze', value: 'Protect From Freeze' },
  { label: 'Re-Class', value: 'Re-Class' },
  { label: 'Redelivery', value: 'Redelivery' },
  { label: 'Residential Delivery', value: 'Residential Delivery' },
  { label: 'Residential Pickup', value: 'Residential Pickup' },
  { label: 'Reweigh Fee', value: 'Reweigh Fee' },
  { label: 'Saturday Pickup', value: 'Saturday Pickup' },
  { label: 'Seal', value: 'Seal' },
  { label: 'Short Pay', value: 'Short Pay' },
  { label: 'Sort & Segregate', value: 'Sort & Segregate' },
  { label: 'Sort & Segregate Delivery', value: 'Sort & Segregate Delivery' },
  { label: 'Sort & Segregate Pickup', value: 'Sort & Segregate Pickup' },
  { label: 'Special Handling Service', value: 'Special Handling Service' },
  { label: 'Tarp', value: 'Tarp' },
  { label: 'Trade Show Delivery', value: 'Trade Show Delivery' },
  { label: 'Trade Show Pickup', value: 'Trade Show Pickup' },
  { label: 'Warehouse Fee', value: 'Warehouse Fee' },
  { label: 'White Glove Service', value: 'White Glove Service' },
  { label: 'Write Off', value: 'Write Off' },
];

export const adjustmentsOptions = [
  { label: '90 Days Retraction', value: 1 },
  { label: '120 Days Retraction', value: 2 },
  { label: '90 Days Refund', value: 3 },
  { label: '120 Days Refund', value: 5 },
  { label: 'In Collections', value: 4 },
];

export const lineItemsTypesForCustomerPay = [
  {
    label: 'Pricing',
    value: 'Pricing',
    disabled: true,
  },
  { label: 'Line Haul', value: 'Line Haul' },
  { label: 'Rate per Mile', value: 'Rate per Mile' },
  { label: 'Rate per Pound', value: 'Rate per Pound' },
  { label: 'Rate per Unit', value: 'Rate per Unit' },
  { label: 'Spot Charge', value: 'Spot Charge' },
  {
    label: 'Accessorials',
    value: 'Accessorials',
    disabled: true,
  },
  { label: 'Accessorial', value: 'Accessorial' },
  { label: 'Bucket Truck', value: 'Bucket Truck' },
  { label: 'Damage', value: 'Damage' },
  { label: 'Dead Head', value: 'Dead Head' },
  { label: 'Detention', value: 'Detention' },
  { label: 'Detention Loading', value: 'Detention Loading' },
  { label: 'Detention Unloading', value: 'Detention Unloading' },
  { label: 'Driver Assist', value: 'Driver Assist' },
  { label: 'Fuel', value: 'Fuel' },
  { label: 'Fumigation', value: 'Fumigation' },
  { label: 'Layover', value: 'Layover' },
  { label: 'Lumper', value: 'Lumper' },
  { label: 'Lumper Loading', value: 'Lumper Loading' },
  { label: 'Lumper Unloading', value: 'Lumper Unloading' },
  { label: 'Out of Route Miles', value: 'Out of Route Miles' },
  { label: 'Positioning', value: 'Positioning' },
  { label: 'Protective Services', value: 'Protective Services' },
  { label: 'Re-Delivery', value: 'Re-Delivery' },
  { label: 'Roll Back', value: 'Roll Back' },
  { label: 'Route Survey', value: 'Route Survey' },
  { label: 'Storage', value: 'Storage' },
  { label: 'Team Service', value: 'Team Service' },
  { label: 'TONU', value: 'TONU' },
  {
    label: 'Fees',
    value: 'Fees',
    disabled: true,
  },
  { label: 'Bonus', value: 'Bonus' },
  { label: 'Check Call', value: 'Check Call' },
  { label: 'Drop Trailer', value: 'Drop Trailer' },
  { label: 'Fee', value: 'Fee' },
  { label: 'Fine', value: 'Fine' },
  { label: 'GPS Tracking', value: 'GPS Tracking' },
  { label: 'Interstate/Highway Toll', value: 'Interstate/Highway Toll' },
  { label: 'Late Fee', value: 'Late Fee' },
  { label: 'New York Delivery Charge', value: 'New York Delivery Charge' },
  { label: 'On-time Delivery', value: 'On-time Delivery' },
  { label: 'On-time Pick Up', value: 'On-time Pick Up' },
  { label: 'Over Weight', value: 'Over Weight' },
  { label: 'Pallet Charge', value: 'Pallet Charge' },
  { label: 'Premium Charge', value: 'Premium Charge' },
  { label: 'Special Handling Service', value: 'Special Handling Service' },
  { label: 'Tarp Fee', value: 'Tarp Fee' },
  { label: 'Unloading', value: 'Unloading' },
  {
    label: 'Intermodal',
    value: 'Intermodal',
    disabled: true,
  },
  { label: 'Block And Brace', value: 'Block And Brace' },
  { label: 'Chassis', value: 'Chassis' },
  { label: 'Diversion', value: 'Diversion' },
  { label: 'Driver Assist', value: 'Driver Assist' },
  { label: 'Driver Count', value: 'Driver Count' },
  { label: 'Driver Dunnage Disposal', value: 'Driver Dunnage Disposal' },
  { label: 'Driver Layover', value: 'Driver Layover' },
  { label: 'Driver Sweep', value: 'Driver Sweep' },
  { label: 'Driver Wash Out', value: 'Driver Wash Out' },
  {
    label: 'Dry Run (TONU) Equipment Not Used',
    value: 'Dry Run (TONU) Equipment Not Used',
  },
  { label: 'Hazmat', value: 'Hazmat' },
  { label: 'High Value Seal Charge', value: 'High Value Seal Charge' },
  { label: 'Lumper Loading', value: 'Lumper Loading' },
  { label: 'Lumper Unloading', value: 'Lumper Unloading' },
  { label: 'Miscellaneous', value: 'Miscellaneous' },
  { label: 'Night Delivery', value: 'Night Delivery' },
  { label: 'Pier Pass', value: 'Pier Pass' },
  {
    label: 'Power Detention Drop and Hook',
    value: 'Power Detention Drop and Hook',
  },
  {
    label: 'Power Detention Live Unload',
    value: 'Power Detention Live Unload',
  },
  { label: 'Pre-Pull', value: 'Pre-Pull' },
  { label: 'Reconsignment', value: 'Reconsignment' },
  { label: 'Scale Light and Heavy', value: 'Scale Light and Heavy' },
  { label: 'Single Scale Charge', value: 'Single Scale Charge' },
  { label: 'Stop Off', value: 'Stop Off' },
  { label: 'Tri-Axle', value: 'Tri-Axle' },
  { label: 'Yard Pull', value: 'Yard Pull' },
  { label: 'Yard Storage', value: 'Yard Storage' },
  {
    label: 'LTL',
    value: 'LTL',
    disabled: true,
  },
  { label: 'Additional Freight', value: 'Additional Freight' },
  { label: 'Agent Authorized SED', value: 'Agent Authorized SED' },
  { label: 'Airport Delivery', value: 'Airport Delivery' },
  { label: 'Airport Pickup', value: 'Airport Pickup' },
  { label: 'Appointment Delivery', value: 'Appointment Delivery' },
  { label: 'Appointment Pickup', value: 'Appointment Pickup' },
  { label: 'Bill of Lading', value: 'Bill of Lading' },
  { label: 'Blind Shipment', value: 'Blind Shipment' },
  { label: 'CarrierCharge', value: 'CarrierCharge' },
  { label: 'CFS Delivery', value: 'CFS Delivery' },
  { label: 'CFS Pickup', value: 'CFS Pickup' },
  { label: 'Clean Air Surcharge', value: 'Clean Air Surcharge' },
  { label: 'COI Required', value: 'COI Required' },
  { label: 'Correction of Terms', value: 'Correction of Terms' },
  { label: 'Cubic Capacity', value: 'Cubic Capacity' },
  { label: 'Dimensional Weight', value: 'Dimensional Weight' },
  { label: 'Discount', value: 'Discount' },
  { label: 'Gate Fee', value: 'Gate Fee' },
  { label: 'Ground Delivery Service', value: 'Ground Delivery Service' },
  { label: 'Guaranteed Delivery Day', value: 'Guaranteed Delivery Day' },
  { label: 'Handling Fee', value: 'Handling Fee' },
  { label: 'High Cost Area Fee', value: 'High Cost Area Fee' },
  { label: 'Inspection Fee', value: 'Inspection Fee' },
  { label: 'Inside Delivery', value: 'Inside Delivery' },
  { label: 'Inside Pickup', value: 'Inside Pickup' },
  { label: 'Labeling', value: 'Labeling' },
  { label: 'Labor', value: 'Labor' },
  { label: 'Liftgate Delivery', value: 'Liftgate Delivery' },
  { label: 'Liftgate Pickup', value: 'Liftgate Pickup' },
  { label: 'Limited Access Delivery', value: 'Limited Access Delivery' },
  { label: 'Limited Access Pickup', value: 'Limited Access Pickup' },
  { label: 'LOA', value: 'LOA' },
  { label: 'Notification Delivery', value: 'Notification Delivery' },
  { label: 'Over Length 12 < 16', value: 'Over Length 12 < 16' },
  { label: 'Over Length 16 < 20', value: 'Over Length 16 < 20' },
  { label: 'Over Length 20 < 28', value: 'Over Length 20 < 28' },
  { label: 'Over Length 8 < 12', value: 'Over Length 8 < 12' },
  { label: 'Over Length > 28', value: 'Over Length > 28' },
  { label: 'Protect From Freeze', value: 'Protect From Freeze' },
  { label: 'Redelivery', value: 'Redelivery' },
  { label: 'Re-Class', value: 'Re-Class' },
  { label: 'Residential Delivery', value: 'Residential Delivery' },
  { label: 'Residential Pickup', value: 'Residential Pickup' },
  { label: 'Reweigh Fee', value: 'Reweigh Fee' },
  { label: 'Saturday Pickup', value: 'Saturday Pickup' },
  { label: 'Seal', value: 'Seal' },
  { label: 'Sort & Segregate', value: 'Sort & Segregate' },
  { label: 'Sort & Segregate Delivery', value: 'Sort & Segregate Delivery' },
  { label: 'Sort & Segregate Pickup', value: 'Sort & Segregate Pickup' },
  { label: 'Tarp', value: 'Tarp' },
  { label: 'Trade Show Delivery', value: 'Trade Show Delivery' },
  { label: 'Trade Show Pickup', value: 'Trade Show Pickup' },
  { label: 'Warehouse Fee', value: 'Warehouse Fee' },
  { label: 'White Glove Service', value: 'White Glove Service' },
  {
    label: 'Misc',
    value: 'Misc',
    disabled: true,
  },
  { label: 'Adjustment', value: 'Adjustment' },
  { label: 'GST/HST', value: 'GST/HST' },
  { label: 'LTL Insurance', value: 'LTL Insurance' },
  { label: 'Misc', value: 'Misc' },
  { label: 'Paid Wrong Carrier', value: 'Paid Wrong Carrier' },
  { label: 'Short Pay', value: 'Short Pay' },
  { label: 'Write Off', value: 'Write Off' },
];

export const vendorServiceTypes = [
  'Airline/Air Cargo',
  'Bucket Truck',
  'Cross Docking',
  'Customs Brokerage Service',
  'Defrauded Carrier',
  'Drayage',
  'Escort',
  'Excess Cargo Insurance',
  'Forklift Service',
  'Hauling Carrier',
  'Inspection',
  'International',
  'Labor',
  'LTL Insurance',
  'Lumper Services',
  'Ocean Freight- AFL',
  'Ocean Freight- CAN',
  'Other',
  'Railroad',
  'Rebate',
  'Reimbursement',
  'Rental Services',
  'Route Survey',
  'Security',
  'Standard Cargo Insurance',
  'Survey',
  'Tarping Service',
  'Terminal Handling',
  'Tow/Wrecker Service',
  'Transloading Service',
  'Warehousing',
];

//need to tech debt this
export const vendorStatusTypes = ['Open', 'Billed', 'Paid'];

// DialogBox IDs

export const DELETE_DOCUMENT_PAGE_DIALOG_ID = 'deleteDocumentPageDialog';
export const DELETE_CARRIER_PLAYLIST_DIALOG_ID = 'deleteCarrierPlaylistDialog';
