// TODO this file is too big, we need to refactor it
import { ToastType } from '@components';
import { Coordinate } from '@components/Map/Map';
import { getCarrierPlaylists } from '@store/carriers';
import {
  getEffectiveIsEnabledForUserByFeatureName,
  IsFeatureFlagEnabledByName,
} from '@store/Global/service';
import { setLoadBoardStore } from '@store/loadboard';
import { getLTLFalveyPricingQuotes } from '@store/ltl/actions';
import {
  getDefaultAddress,
  getTempQuote,
  LtlReferenceNumberUpdate,
} from '@store/ltl/services';
import {
  saveReferenceNumbers,
  setLoadItems,
  setLTLQuote,
} from '@store/ltl/store';
import {
  FalveyInsuranceQuoteInLoad,
  LTLLoadItem,
  LTLReferenceNumber,
  LTLReferenceNumberTypes,
  TempQuoteModel,
} from '@store/ltl/types';
import {
  carrierUpdateInsurance,
  checkCarrierConditional,
  createPostLoad,
  deleteLoadComment,
  deleteOrderTemplateService,
  deletePosting,
  enableTracking,
  fetchCarrierDetail,
  fetchCarrierFeatures,
  fetchCoordinatesFromAddress,
  fetchCoordinatesFromZip,
  fetchCopyOrder,
  fetchCustomerInfo,
  fetchEdiLogs,
  fetchEdiSettingsValueData,
  fetchFeatureFlagGetCustomerFeatures,
  fetchLoadTender,
  fetchOrder,
  fetchOrderDocuments,
  fetchOrderLoadboard,
  fetchOrderTemplateCall,
  fetchOrderTemplates,
  fetchPastStops,
  fetchPosting,
  fetchTopStops,
  getCustomerContact,
  getNearbyCarriers,
  postCopiedOrder,
  postLoadComment,
  postLoadStatus,
  postPosting,
  putLoadCommentVisibility,
  saveOrder,
  saveOrderTemplateCall,
  sendEDIUpdate,
  setRateConRecieved,
  updatePostLoad,
  voidOrder,
  voidVendor,
} from '@store/orders/services';
import {
  carrierStore,
  orderStore,
  setCarrierLoading,
  setCarrierStore,
  setCopiedOrderId,
  setCustomerData,
  setCustomerLoading,
  setDocuments,
  setDocumentsLoading,
  setEdiLogsData,
  setEdiLogsStore,
  setEdiSettingsValuesData,
  setEdiSettingsValuesError,
  setEdiSettingsValuesLoading,
  setFeatureFlagGetCustomerFeaturesData,
  setFeatureFlagGetCustomerFeaturesError,
  setFeatureFlagGetCustomerFeaturesLoading,
  setLoadTenderStore,
  setManagePostLoadData,
  setMapCords,
  setOrderLoading,
  setOrderStore,
  setPostLoadData,
  setPostLoadStore,
  setStops,
} from '@store/orders/store';
import {
  Carrier,
  CarrierFeatures,
  Comments,
  EdiUpdate,
  ICustomerContact,
  ILatLon,
  ILoadComments,
  ILoadEditViewModel,
  IManagePostLoad,
  IOrderViewModel,
  IPostLoad,
  LoadEdiStatusUpdatePayload,
  loadStatus,
  NearbyCarrierRequestViewModel,
  OrderState,
  TabState,
  TrackingData,
  VendorContractViewModel,
} from '@store/orders/types';
import { Address } from '@store/orders/v3Types';
import { Contact } from '@typeDefinitions/contactTypes';
import { userStore } from '@store/user';
import { logError } from '@utils/errorLogging';
import { mergeOrderQuote } from '@utils/ltl/MergeQuoteOrderBuilder';
import { routeOrderDetails } from '@utils/routeOrderDetails';
import { handleToast } from '@utils/utils';
import { validateOrder } from '@utils/validationUtils';
import { ICityState, IZip } from '@views/order/components';
import { TrackingCommentSchema } from '@views/order/components/customerTracking';
import { orderFormSchema } from '@views/order/validations/orderSchema';
import { omit } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import { produce, unwrap } from 'solid-js/store';
import { v4 as uuidv4 } from 'uuid';
import { ValidationError } from 'yup';
import { isFeatureFlagEnabled } from '@store/featureFlags/store';
import { featureFlags } from '@store/featureFlags/featureFlags';
import { DateTime } from 'luxon';
import { isDateLessThanCurrentDate } from '@utils/dateUtils';

import {
  addCustomerPayLineItem,
  addVendorPayLineItem,
  buildLoadFromQuote,
  copyLoad,
  updateCustomerAndVendorPay,
  updateVendorPayLineItem,
} from './helper';
import { mapEditPostLoad } from './mapper';

export const quickPostLoad = async (loadId: number, postingId: number) => {
  try {
    setLoadBoardStore('overlay', true);
    if (postingId) {
      const deleteResponse = await deletePosting(postingId);
      if (Boolean(deleteResponse)) {
        setLoadBoardStore(
          'items',
          (items) => items.loadId === loadId,
          'postingId',
          0,
        );
      }
    } else {
      const postingResponse = await postPosting(loadId);
      if (Boolean(postingResponse)) {
        setLoadBoardStore(
          'items',
          (items) => items.loadId === loadId,
          'postingId',
          postingResponse,
        );
      }
    }
    setLoadBoardStore('overlay', false);
  } catch (error) {
    setLoadBoardStore('overlay', false);
  }
};

type NavigateFunction = (to: string) => void;

export const TrySaveOrder = async (
  navigate?: NavigateFunction,
  params?: string,
) => {
  await saveOrderDetails(async (order: IOrderViewModel) => {
    if (orderStore.isCreate) {
      const isNative = routeOrderDetails(order.id);
      if (isNative) {
        navigate && navigate(`/order/details/${order.id}`);
      }
    } else {
      if (params !== undefined && params !== '') {
        navigate && navigate(`/order/details/${order.id}`);
      }
    }

    await saveReferenceNumbers();

    const updatedLoads = order.loads.map((load) => {
      return {
        ...load,
        stops:
          load.stops?.map((s) => {
            return {
              ...s,
              shouldSendEdiBooleanVals: {
                shouldSendPickupAppointmentAuto214: false,
                shouldSendDropoffAppointmentAuto214: false,
                shouldSendDropoffDriverInAuto214: false,
                shouldSendPickupDriverInAuto214: false,
                shouldSendPickupDriverOutAuto214: false,
                shouldSendDropoffDriverOutAuto214: false,
              },
            };
          }) ?? [],
        quoteItems: load.items,
        quoteStops: load.stops,
      };
    });

    //Putting this in a settimout so as to not trigger the createEffect for mergeOrderWithQuote
    setTimeout(() => {
      order.loads = updatedLoads as ILoadEditViewModel[];
      setOrderStore('order', order);
      setOrderStore('isCreate', false);
      setOrderStore('matched', false);
    }, 0);
  });
};

export const toggleRateCon = async (loadId: number, recieved: boolean) => {
  try {
    setLoadBoardStore('overlay', true);
    const apiResonse = await setRateConRecieved(loadId, recieved);
    if (Boolean(apiResonse)) {
      setLoadBoardStore(
        'items',
        (items) => items.loadId === loadId,
        'confirmationSigned',
        recieved,
      );
    }
    setLoadBoardStore('overlay', false);
  } catch (error) {
    setLoadBoardStore('overlay', false);
  }
};

export const changeLoadStatus = async (loadId: number, status: string) => {
  const loadStatus: loadStatus = {
    id: loadId,
    status: status,
  };
  try {
    setLoadBoardStore('overlay', true);
    await postLoadStatus(loadStatus);
    setLoadBoardStore(
      'items',
      (items) => items.loadId === loadId,
      'status',
      status,
    );
    setOrderStore('order', 'loads', 0, 'status', status);
    setLoadBoardStore('overlay', false);
  } catch (error) {
    setLoadBoardStore('overlay', false);
  }
};

export const fetchOrderForLoadBoard = async (orderId: string) => {
  try {
    const load = await fetchOrderLoadboard(orderId);

    setOrderStore(() => ({
      order: {
        ...load,
      },
    }));
  } catch (error) {
    setOrderStore(() => ({
      isError: true,
    }));
  } finally {
    setOrderStore(() => ({
      loading: false,
    }));
  }
};

export const fetchOrderDetails = async (orderId: string, quoteId?: string) => {
  try {
    setOrderLoading(true);
    const order = await fetchOrder(orderId);

    void (async () => {
      if (order.loads[0].carrierId != null) {
        await getCarrierFeatures(order.loads[0].carrierId, 0);
        await getCarrierDetail(order.loads[0].carrierId, 0);
      }

      // need to discuss
      if (order.loads[0].postingId !== null) {
        await fetchPostLoadByPostingId(order.loads[0].postingId);
      }

      await getCarrierPlaylists();
    })();

    const updatedLoads = order.loads.map((load) => ({
      ...load,
      quoteItems: load.items,
      quoteStops: load.stops,
      //Here be dragons
      //WARNING: NOT TO Touch below class map function else it will break the stops line items
      items: load.items?.map((item) => {
        return {
          ...item,
          class: item.class,
        };
      }),
      stops:
        load.stops?.map((s) => {
          return {
            ...s,
            shouldSendEdiBooleanVals: {
              shouldSendPickupAppointmentAuto214: false,
              shouldSendDropoffAppointmentAuto214: false,
              shouldSendDropoffDriverInAuto214: false,
              shouldSendPickupDriverInAuto214: false,
              shouldSendPickupDriverOutAuto214: false,
              shouldSendDropoffDriverOutAuto214: false,
            },
          };
        }) ?? [],
    }));
    setOrderStore(() => ({
      order: {
        ...order,
        loads: updatedLoads,
      },
    }));
    //Check Falvey Load Index available or not
    const falveyLoadIndex = order.loads.findIndex((load) =>
      Boolean(load.ltlQuote?.ltlInsurance),
    );

    if (
      falveyLoadIndex !== -1 &&
      order.loads[falveyLoadIndex].ltlQuote?.ltlInsurance
    ) {
      const { shippingSumInsured, quotePremium, quoteServiceFee } =
        order.loads[falveyLoadIndex].ltlQuote?.ltlInsurance;
      // NOTE: Insert customer and vendor pay if user save the order and added declared and not saved the order and refresh the page
      if (order.lineItems.findIndex((i) => i.type === 'LTL Insurance') === -1) {
        updateCustomerAndVendorPay(
          {
            shippingSumInsured,
            quotePremium,
            quoteServiceFee,
          } as FalveyInsuranceQuoteInLoad,
          'insert',
          updatedLoads[falveyLoadIndex].ltlQuote?.ltlInsurance
            ?.shippingSumInsured as number,
        );
      }
    }

    if (quoteId !== undefined) {
      await mergeOrderWithQuote(quoteId, order);
    }
  } catch (error) {
    setOrderStore(() => ({
      isError: true,
    }));
  } finally {
    setOrderStore(() => ({
      loading: false,
    }));
  }
};

export const fetchCopyOrderDetails = async (orderId: string) => {
  try {
    setOrderLoading(true);
    const order = await fetchCopyOrder(orderId);

    if (order.loads[0].carrierId != null) {
      await getCarrierFeatures(order.loads[0].carrierId, 0);
      await getCarrierDetail(order.loads[0].carrierId, 0);
    }
    // need to discuss
    await getCarrierPlaylists();
    const updatedLoads = order.loads.map((load) => ({
      ...load,
      quoteItems: load.items,
      stops: load.stops?.map((s) => {
        if (s.stopDateTime === null || s.stopDateTime === undefined) {
          return {
            stopDateTime: undefined,
            ...s,
          };
        }
        const stopDateTime = DateTime.fromISO(s.stopDateTime);
        if (isDateLessThanCurrentDate(stopDateTime) || s.dropOff) {
          // we need to handle stopDateTime where it should go to undefined so the user can select the date
          const newStop = {
            ...s,
            stopDateTime: undefined,
            stopDateFormatted: undefined,
          };
          return newStop;
        }
        return {
          ...s,
        };
      }),
      quoteStops: load.stops,
      //Here be dragons
      //WARNING: NOT TO Touch below class map function else it will break the stops line items
      items: load.items?.map((item) => {
        return {
          ...item,
          class: item.class,
        };
      }),
    }));
    setOrderStore(() => ({
      order: {
        ...order,
        loads: updatedLoads,
      },
    }));
  } catch (error) {
    setOrderStore(() => ({
      isError: true,
    }));
  } finally {
    setOrderStore(() => ({
      loading: false,
    }));
  }
};

export const loadCustomerInfo = async (customerId: number) => {
  setCustomerLoading(true);

  const customerInfo = await fetchCustomerInfo(customerId);
  await loadEdiSettingsValueData(customerId);
  await loadFeatureFlagGetCustomerFeatures(customerId);
  setCustomerData(customerInfo);

  if (Boolean(customerInfo.assignAccountManagerAsOrderRep)) {
    updateOrderState({
      owner: customerInfo.accountManager,
      assignedId: customerInfo.accountManagerId,
    });
  }

  setCustomerLoading(false);
};

export const loadEdiSettingsValueData = async (customerId: number) => {
  setEdiSettingsValuesLoading(true);
  try {
    const response = await fetchEdiSettingsValueData(customerId);
    setEdiSettingsValuesData(response);
  } catch (error) {
    setEdiSettingsValuesError(true);
  } finally {
    setEdiSettingsValuesLoading(false);
  }
};

export const loadFeatureFlagGetCustomerFeatures = async (
  customerId: number,
) => {
  setFeatureFlagGetCustomerFeaturesLoading(true);
  try {
    const response = await fetchFeatureFlagGetCustomerFeatures(customerId);
    setFeatureFlagGetCustomerFeaturesData(response);
  } catch (error) {
    setFeatureFlagGetCustomerFeaturesError(true);
  } finally {
    setFeatureFlagGetCustomerFeaturesLoading(false);
  }
};

export const fetchStopsForStopsModal = async () => {
  const customerId = orderStore.order.customerId;
  const [pastStops, topStops] = await Promise.all([
    fetchPastStops(customerId),
    fetchTopStops(customerId),
  ]);
  setStops(topStops, pastStops);
};

export const removeComment = async (commentId: number, loadId: number) => {
  await deleteLoadComment(commentId);
  setOrderStore(
    'order',
    'loads',
    (load) => load.id === loadId,
    'comments',
    (comments) => comments?.filter((comment) => comment.id !== commentId),
  );
};

export const changeCommentVisibility = async (
  commentId: number,
  loadId: number,
) => {
  await putLoadCommentVisibility(commentId);
  setOrderStore(
    'order',
    'loads',
    (load) => load.id === loadId,
    'comments',
    (comments) =>
      comments?.map((comment) =>
        comment.id === commentId
          ? { ...comment, visibleToCustomer: !comment.visibleToCustomer }
          : comment,
      ),
  );
};

export const addNewLoadToOrder = (load: ILoadEditViewModel) => {
  setOrderStore(
    produce((draft) => {
      draft.order.loads.push(load);
    }),
  );
};

export const removeLoadFromOrder = (loadIndex: number) => {
  setOrderStore(
    'order',
    'loads',
    produce((loads) => {
      loads.splice(loadIndex, 1);
    }),
  );
  setActiveCarrierTab({ index: 0, type: 'load' } as TabState);
};

export const removeVendorFromOrder = (vendorIndex: number) => {
  setOrderStore(
    'order',
    'vendors',
    produce((vendors) => {
      vendors?.splice(vendorIndex, 1);
    }),
  );
  setActiveCarrierTab({ index: 0, type: 'load' } as TabState);
};

export const copyOrder = async (copyMultiple?: boolean) => {
  const currentOrder = unwrap(orderStore.order);
  const isLTL = currentOrder.loads.some((load) => load.mode === 'LTL');

  const newOrder: IOrderViewModel = {
    hideCustomerName: currentOrder.hideCustomerName,
    comments:
      copyMultiple !== undefined && copyMultiple
        ? []
        : [
            {
              comment: 'Copied from Order# ' + currentOrder.id,
              timestamp: new Date().toISOString(),
              id: 0,
              objectId: currentOrder.id,
              type: 'Order',
              operationType: 'Insert',
              name: userStore.user.name,
              userId: userStore.user.id,
            },
          ],
    customerContact: currentOrder.customerContact,
    customerId: currentOrder.customerId,
    chargeCreditCardFee: currentOrder.chargeCreditCardFee,
    customerName: currentOrder.customerName,
    customer: currentOrder.customer,
    customerCredit: currentOrder.customerCredit,
    customerContactId: currentOrder.customerContactId,
    availableCredit: currentOrder.availableCredit,
    paymentTerms: currentOrder.paymentTerms,
    owner: currentOrder.owner,
    agingOut: false,
    availableServices: currentOrder.availableServices,
    metadata: currentOrder.metadata,
    customerPrePayments: undefined,
    synched: false,
    bidType: currentOrder.bidType,
    edi: currentOrder.edi,
    trackLoads: currentOrder.trackLoads,
    private: currentOrder.private,
    show214: currentOrder.show214,
    daysPastDue: 0,
    canVoidOrder: false,
    isDeleted: false,
    loadInformation: currentOrder.loadInformation,
    id: 0,
    accountingSysId: undefined,
    needsApprovalFlag: undefined,
    operationType: 'Insert',
    createdBy: 0,
    createdDate: new Date(),
    loadTenderId: null,
    lineItems: isLTL
      ? []
      : orderStore.order.lineItems
          .filter((lineItem) => lineItem.operationType !== 'Delete')
          .map((lineItem) => ({
            type: lineItem.type,
            description: lineItem.description,
            quantity: lineItem.quantity,
            rate: lineItem.rate,
            estimate: lineItem.estimate,
            adjustment: lineItem.adjustment,
            stopId: undefined,
            descriptionWithParanthesis: lineItem.descriptionWithParanthesis,
            createdDate: new Date(),
            referenceNumber: null,
            rateDetail: lineItem.rateDetail,
            operationType: 'Insert',
            id: 0,
            orderId: 0,
            status: 'Unsettled',
            readOnly: false,
            synched: false,
            settledDate: null,
          })),
    vendors: orderStore.order.vendors
      ?.filter(
        (vendor) =>
          vendor.operationType !== 'Delete' &&
          vendor.name !== 'Falvey Shippers Insurance',
      )
      .map((vendor) => ({
        operationType: 'Insert',
        id: 0,
        orderId: 0,
        synched: false,
        settledDate: null,
        description: vendor.description,
        invoicedDate: undefined,
        paidDate: undefined,
        name: vendor.name,
        mailingAddress1: vendor.mailingAddress1,
        mailingAddress2: vendor.mailingAddress2,
        mailingCity: vendor.mailingCity,
        mailingState: vendor.mailingState,
        mailingZip: vendor.mailingZip,
        countryCode: vendor.countryCode,
        serviceType: vendor.serviceType,
        serviceTypeText: vendor.serviceTypeText,
        currencyType: vendor.currencyType,
        contactName: vendor.contactName,
        contactPhone: vendor.contactPhone,
        status: vendor.status,
        payments: undefined,
        vendorPayDisplay: vendor.vendorPayDisplay,
        otherServiceType: vendor.otherServiceType,
        lineItems: vendor.lineItems
          ?.filter((lineItem) => lineItem.operationType !== 'Delete')
          .map((lineItem) => ({
            operationType: 'Insert',
            id: 0,
            orderId: 0,
            status: 'Unsettled',
            readOnly: false,
            synched: false,
            settledDate: null,
            type: lineItem.type,
            description: lineItem.description,
            quantity: lineItem.quantity,
            rate: lineItem.rate,
            estimate: lineItem.estimate,
            adjustment: lineItem.adjustment,
            stopId: undefined,
            descriptionWithParanthesis: lineItem.descriptionWithParanthesis,
            createdDate: new Date(),
            referenceNumber: null,
            rateDetail: lineItem.rateDetail,
            vendorContractId: lineItem.vendorContractId,
          })),
      })),
    payments: [],
    adjustments: [],
    collectionActions: undefined,
    referenceNumber: '',
    flags: [],
    status: 'Open',
    editable: true,
    invoicedDate: undefined,
    paidDate: undefined,
    billingHold: false,
    assignedId: userStore.user.id,
    currencyType: currentOrder.currencyType,
    loads: orderStore.order.loads.map(
      (load) => copyLoad(load) as ILoadEditViewModel,
    ),
  };

  if (copyMultiple === undefined || !copyMultiple) {
    const copyOrderId = uuidv4();
    setCopiedOrderId(copyOrderId);
    await postCopiedOrder(copyOrderId, newOrder);
  }
  return newOrder;
};

export const copyCarrier = (load: ILoadEditViewModel) => {
  //breaking these into local assignments, in case we need to alter
  // this copy logic later where more conditionals maybe needed
  const perserveCarrier = load.mode === 'LTL';
  // const perserveStopDateAndTimes = load.mode === 'LTL';
  const newLoad = copyLoad(load, perserveCarrier, false, true);

  const sequence =
    orderStore.order.loads.reduce(
      (max: number, load: { sequence: number }) => Math.max(max, load.sequence),
      0,
    ) + 1;
  newLoad.sequence = sequence;
  newLoad.loadNum = `${load.orderId}-${sequence}`;
  updateOrderState({ loads: [...orderStore.order.loads, newLoad] });
};

export const copyCarrierDetails = (
  carrier: Carrier,
  featureFlag: CarrierFeatures,
) => {
  setCarrierStore(
    produce((draft) => {
      if (draft.carrier.length > 0) {
        draft.carrier.push(carrier);
      }
      if (draft.featureFlags.length > 0) {
        draft.featureFlags.push(featureFlag);
      }
    }),
  );
};

export const addNewVendorToOrder = (vendor: VendorContractViewModel) => {
  setOrderStore('order', 'vendors', (vendors) => [...(vendors || []), vendor]);
};

export const setActiveCarrierTab = (tab: TabState) => {
  setOrderStore('activeTab', tab);
};

export const fetchDocuments = async (orderId: string) => {
  try {
    const documents = await fetchOrderDocuments(orderId);
    setDocuments(documents);
  } catch (error) {
    handleToast(ToastType.Error, (error as Error).message);
  } finally {
    setDocumentsLoading(false);
  }
};

export const sendComment = async (comment: TrackingCommentSchema) => {
  try {
    const response = await postLoadComment(comment);

    const commentNew: ILoadComments = {
      city: response.city,
      comment: response.comment,
      id: response.id,
      name: response.name,
      state: response.state,
      temperature: response.temperature,
      timestamp: response.timestamp,
      visibleToCustomer: response.visibleToCustomer,
      latitude: Number(comment.latitude),
      longitude: Number(comment.longitude),
      milesRemaining: response.milesRemaining,
      objectId: response.objectId,
      operationType: response.operationType,
      thumbnail: response.thumbnail,
      type: response.type,
      userId: response.userId,
    };

    setOrderStore(
      'order',
      'loads',
      (load: ILoadEditViewModel) => load.id === comment.loadId,
      'comments',
      (comments) => [commentNew, ...(comments || [])],
    );
  } catch (error) {
    logError('sendComment', error as Error);
    handleToast(ToastType.Error, (error as Error).message);
  }
};

export const getStopCoordinates = async (addressArray: IZip[]) => {
  try {
    const response = await fetchCoordinatesFromZip(addressArray);
    const latlngArray: Coordinate[] = [];
    response.forEach((geoElement: ILatLon) => {
      latlngArray.push({
        lat: Number(geoElement.Coords.Lat),
        lng: Number(geoElement.Coords.Lon),
      });
    });
    //update store
    setMapCords(latlngArray);
  } catch (error) {
    handleToast(ToastType.Error, (error as Error).message);
    logError('getStopCoordinates', error as Error);
  }
};

export const getCoordinates = async (addressArray: ICityState[]) => {
  try {
    const response = await fetchCoordinatesFromAddress(addressArray);
    const latlngArray: Coordinate[] = [];
    response.forEach((geoElement: ILatLon) => {
      latlngArray.push({
        lat: Number(geoElement.Coords.Lat),
        lng: Number(geoElement.Coords.Lon),
      });
    });
    return latlngArray;
  } catch (error) {
    handleToast(ToastType.Error, (error as Error).message);
    logError('getCoordinates', error as Error);
    return [];
  }
};

export const loadCustomerEdiLogs = async (
  customerId: number,
  shipmentId: string | undefined,
) => {
  setEdiLogsStore(() => ({
    loading: true,
  }));
  try {
    const ediTransactionHistory = await fetchEdiLogs(customerId, shipmentId);

    setEdiLogsData(ediTransactionHistory);
  } catch (error) {
    logError('loadCustomerEdiLogs', error as Error);
    setEdiLogsStore(() => ({ isError: true }));
    handleToast(ToastType.Error, (error as Error).message);
  } finally {
    setEdiLogsStore(() => ({
      loading: false,
    }));
  }
};

export const updateOrderField = (field: string, value: unknown) => {
  if (field === 'loadsPayments') {
    setOrderStore(
      produce((draft) => {
        if (draft.order.loads.length > 0) {
          draft.order.loads[orderStore.activeTab.index].payments.push(value);
        }
      }),
    );
  }
};

export const fetchLoadTenderDetails = async (orderId: number | string) => {
  try {
    const load = await fetchLoadTender(orderId);
    setLoadTenderStore('loadTender', load);
  } catch (error) {
    setLoadTenderStore(() => ({
      isError: true,
    }));
    setLoadTenderStore('isError', true);
    logError('fetchLoadTenderDetails', error as Error);
    handleToast(ToastType.Error, (error as Error).message);
  } finally {
    setLoadTenderStore('loading', false);
  }
};

export const saveOrderDetails = async (
  cb?: (order: IOrderViewModel) => void,
) => {
  const isReadFromNewLtlTablesEnabled = isFeatureFlagEnabled(
    featureFlags.readFromNewLtlTables,
  );
  try {
    setOrderStore('isSaving', true);
    const someLTL = orderStore.order.loads.some((x) => x.mode === 'LTL');
    if (someLTL) {
      for (const load of orderStore.order.loads) {
        if (
          isReadFromNewLtlTablesEnabled() &&
          (load.referenceNumbers?.operationType === 'Insert' ||
            load.referenceNumbers?.operationType === 'Update')
        ) {
          await LtlReferenceNumberUpdate(
            load.id,
            load.referenceNumbers.referenceNumbers,
          );
        } else if (
          !isReadFromNewLtlTablesEnabled() &&
          load.ltlShipmentData !== undefined &&
          load.ltlShipmentData !== null &&
          (load.ltlShipmentData.operationType === 'Insert' ||
            load.ltlShipmentData.operationType === 'Update')
        ) {
          await handleLegacyUpdateRefNumbers(load);
        }
      }
    }
    let allEdiUpdates: EdiUpdate[] = [];
    const payload: IOrderViewModel = {
      ...orderStore.order,
      loads: orderStore.order.loads.map((l) => {
        return {
          ...l,
          stops:
            l.stops?.map((s, idx) => {
              const { ediUpdates } = s;
              if (s.operationType !== 'Delete') {
                allEdiUpdates = allEdiUpdates.concat(ediUpdates ?? []);
              }
              return {
                ...s,
                operationType: ['Insert', 'Delete'].includes(s.operationType)
                  ? s.operationType
                  : 'Update',
                stopOrder: idx,
              };
            }) ?? [],
        };
      }),
    };
    const order = await saveOrder(unwrap(payload));
    await Promise.all(
      allEdiUpdates.map(async (update) => {
        try {
          const resp = (await sendEDIUpdate(
            update.loadId,
            update as unknown as LoadEdiStatusUpdatePayload,
          )) as unknown as { success: boolean };
          if (Boolean(resp.success)) {
            handleToast(ToastType.Success, 'EDI Update sent successfully');
          }
        } catch (error) {
          handleToast(ToastType.Error, (error as Error).message);
        }
      }),
    );
    setOrderStore('isSaving', false);
    cb && cb(order);
    // Delete falvey vendor if declared value is removed
    if (orderStore.order.vendors) {
      const falveyVendorIndex = orderStore.order.vendors.findIndex(
        (vendor) => vendor.name === 'Falvey Shippers Insurance',
      );
      const falveyLoadIndex = orderStore.order.loads.findIndex((load) =>
        Boolean(load.ltlQuote?.ltlInsurance),
      );

      if (falveyVendorIndex !== -1 && falveyLoadIndex === -1) {
        await voidVendor(
          orderStore.order.vendors[falveyVendorIndex].id as number,
          falveyVendorIndex,
        );
      }
    }
    setLoadTenderStore('loading', false);
    setOrderStore('isSaving', false);
  } catch (error) {
    logError('saveOrderDetails', error as Error);
    setLoadTenderStore('isError', true);
    setLoadTenderStore('loading', false);
    setOrderStore('isSaving', false);
    throw error;
  }
};

//OLD LTL CALL
export const handleLegacyUpdateRefNumbers = async (
  load: ILoadEditViewModel,
) => {
  const oldLtlReferenceNumbers: LTLReferenceNumber[] = [];
  if (
    typeof load.ltlShipmentData?.proNumber === 'string' &&
    load.ltlShipmentData.proNumber.trim() !== ''
  ) {
    oldLtlReferenceNumbers.push({
      type: LTLReferenceNumberTypes.ProNumber,
      value: load.ltlShipmentData.proNumber,
      internalName: '',
      displayName: '',
      sequence: 1,
      modifiable: true,
    });
  }
  if (
    typeof load.ltlShipmentData?.pickupNumber === 'string' &&
    load.ltlShipmentData.pickupNumber.trim() !== ''
  ) {
    oldLtlReferenceNumbers.push({
      type: LTLReferenceNumberTypes.Pickup,
      value: load.ltlShipmentData.pickupNumber,
      internalName: '',
      displayName: '',
      sequence: 2,
      modifiable: true,
    });
  }
  await LtlReferenceNumberUpdate(load.id, oldLtlReferenceNumbers);
};

export const updateOrderState = (updates: Partial<IOrderViewModel>) => {
  Object.entries(updates).forEach(([key, value]) => {
    setOrderStore('order', key as keyof IOrderViewModel, value);
  });
  if (orderStore.order.operationType === 'None') {
    setOrderStore('order', 'operationType', 'Update');
  }
};

export const updateLoadPropertyAtIndex = (
  propertiesToUpdate: Partial<ILoadEditViewModel>,
  idx?: number, // this change is added so as to update the load at a specific index of choice or by default it will set to the active tab index
) => {
  setOrderStore(
    'order',
    'loads',
    produce((loads) => {
      for (const property in propertiesToUpdate) {
        if (propertiesToUpdate.hasOwnProperty(property)) {
          const value =
            propertiesToUpdate[property as keyof ILoadEditViewModel];
          const index = idx ?? orderStore.activeTab.index;
          if (
            typeof value === 'string' ||
            typeof value === 'number' ||
            typeof value === 'boolean' ||
            value === null ||
            value === undefined
          ) {
            if (
              !('operationType' in loads[index]) ||
              loads[index].operationType === 'None'
            ) {
              loads[index].operationType = 'Update';
            }
          }
          loads[index][property] = value;
          if (property === 'items') {
            const t = value as LTLLoadItem[];
            setLoadItems(
              t.map((item) => {
                return {
                  ...item,
                  item: item.item,
                  description: item.description,
                  type: item.item,
                };
              }),
            );
          }
        }
      }
    }),
  );
};

export const updateVendorPropertyAtIndex = (
  propertiesToUpdate: Partial<VendorContractViewModel>,
  idx?: number,
) => {
  setOrderStore(
    'order',
    'vendors',
    produce((vendors) => {
      for (const property in propertiesToUpdate) {
        if (propertiesToUpdate.hasOwnProperty(property)) {
          const value = propertiesToUpdate[property];
          const index = idx ?? orderStore.activeTab.index;
          if (['string', 'number', 'boolean'].includes(typeof value)) {
            if (vendors[index].operationType === 'None') {
              vendors[index].operationType = 'Update';
            }
          }
          vendors[index][property] = value;
        }
      }
    }),
  );
};

export const updateActiveTabState = (updates: Partial<TabState>) => {
  Object.entries(updates).forEach(([key, value]) => {
    setOrderStore('activeTab', key as keyof TabState, value);
  });
};

export const updateOrderFormErrorState = (
  errors: Record<string, string[] | undefined> | null,
) => {
  setOrderStore(() => ({
    orderFormError: errors,
  }));
};

export const updateOrderFormMappedErrorSection = (
  errors: Record<string, string> | null,
) => {
  setOrderStore(() => ({
    mappedErrorSection: errors,
  }));
};

export const updateOrderFormErrorPerSectionState = (
  errors: OrderState['orderFormErrorPerSection'],
) => {
  setOrderStore(
    produce((draft) => {
      draft.orderFormErrorPerSection = errors;
    }),
  );
};

export const validateFieldForOrder = async (
  validatePath: string,
  errorPath: string,
) => {
  const updateErrors = (error: unknown) => {
    const updatedErrors = {
      ...(unwrap(orderStore.orderFormError) || {}),
      [errorPath]: error,
    };

    if (error === undefined || error === null) delete updatedErrors[errorPath];
    updateOrderFormErrorState(updatedErrors);
  };

  try {
    await orderFormSchema.validateAt(validatePath, orderStore.order);
    const mappedErrorSection = unwrap(orderStore.mappedErrorSection);
    if (mappedErrorSection && mappedErrorSection[errorPath]) {
      const errorsPerSection = { ...orderStore.orderFormErrorPerSection };
      errorsPerSection[
        mappedErrorSection[errorPath] as keyof typeof errorsPerSection
      ] -= 1;
      updateOrderFormErrorPerSectionState(errorsPerSection);
      mappedErrorSection[errorPath] = undefined;
      updateOrderFormMappedErrorSection(mappedErrorSection);
    }
    updateErrors(undefined);
  } catch (validationError: unknown) {
    if (validationError instanceof ValidationError) {
      updateErrors(validationError.errors);
      await validateOrder();
    }

    logError('validateFieldForOrder', validationError as Error);
  }
};

export const getCarrierDetail = async (
  Id: number,
  index: number,
  reassign = false,
) => {
  setCarrierLoading(true);
  try {
    const carrierData = await fetchCarrierDetail(Id);
    setCarrierStore(
      produce((draft) => {
        draft.carrier[index] = carrierData;
      }),
    );
    if (
      (carrierData.hasExpiredCargoInsurance ||
        carrierData.hasExpiredLiabilityInsurance) &&
      (carrierData.mc || carrierData.dot)
    ) {
      await checkCarrierUpdateInsurance(carrierData.mc, carrierData.dot);
    } else if ((carrierData.mc || carrierData.dot) && reassign) {
      await getCarrierConditional(carrierData.mc, carrierData.dot);
    }
    updateCarrierStoreContactsWithLoadCarrier(index);
  } catch (error) {
    logError('getCarrierDetail', error as Error);
  } finally {
    setCarrierLoading(false);
  }
};

export const getCarrierFeatures = async (id: number, index: number) => {
  const featureFlags = await fetchCarrierFeatures(id);
  if (featureFlags) {
    setCarrierStore(
      produce((draft) => {
        draft.featureFlags[index] = featureFlags;
      }),
    );
  }
};

export const checkCarrierUpdateInsurance = async (mc: string, dot: string) => {
  const response = await carrierUpdateInsurance(mc, dot);
  if (response.isSuccess) {
    if (
      !response.value.hasExpiredCargoInsurance ||
      !response.value.hasExpiredLiabilityInsurance
    ) {
      setCarrierStore(
        produce((draft) => {
          draft.carrier[orderStore.activeTab.index] = {
            ...draft.carrier[orderStore.activeTab.index],
            hasExpiredCargoInsurance: response.value.hasExpiredCargoInsurance,
            hasExpiredLiabilityInsurance:
              response.value.hasExpiredLiabilityInsurance,
          };
        }),
      );
      handleToast(
        ToastType.Success,
        //eslint-disable-next-line
        "New Insurance Information found, updated Carrier's record",
      );
    } else
      handleToast(
        ToastType.Caution,
        'Auto Insurance update was unsuccessful, updated information was not found',
      );
  } else {
    handleToast(
      ToastType.Error,
      typeof response.errors !== 'undefined'
        ? response.errors[0].message
        : 'Failed to update insurance information',
    );
  }
};

export const getCarrierConditional = async (mc: string, dot: string) => {
  const response = await checkCarrierConditional(mc, dot);
  if (response && !response.success)
    handleToast(ToastType.Error, response.message.join(','));
};

export const addCommentInOrderStore = (comment: Comments) => {
  setOrderStore((prev: OrderState) => {
    const date = new Date(comment.timestamp!);
    const formattedDate = date.toISOString().replace('Z', '');
    comment.timestamp = formattedDate;
    return {
      ...prev,
      order: {
        ...prev.order,
        comments: [...(prev.order.comments ?? []), comment],
      },
    };
  });
};

export const deleteCommentInOrderStore = (commentId: number) => {
  setOrderStore((prev: OrderState) => {
    return {
      ...prev,
      order: {
        ...prev.order,
        comments: prev.order.comments?.filter((c) => c.id !== commentId),
      },
    };
  });
};
export const savePostLoad = async (formValues: IPostLoad) => {
  try {
    const response = await createPostLoad(formValues);
    await fetchPostLoadByPostingId(response.id);
    setPostLoadData(response);
    updateLoadPropertyAtIndex({ postingId: response.id });
  } catch (error) {
    logError('savePostLoad', error as Error);
    setPostLoadStore(() => ({
      isError: true,
    }));
  } finally {
    setPostLoadStore(() => ({
      loading: false,
    }));
  }
};

export const editPostLoad = async (formValues: IManagePostLoad) => {
  try {
    const response = await updatePostLoad(mapEditPostLoad(formValues));
    setManagePostLoadData(response);
  } catch (error) {
    logError('editPostLoad', error as Error);
    setPostLoadStore(() => ({
      isError: true,
    }));
  } finally {
    setPostLoadStore(() => ({
      loading: false,
    }));
  }
};

export const fetchPostLoadByPostingId = async (postingId: number | null) => {
  try {
    const response = await fetchPosting(postingId);
    setManagePostLoadData(response);
  } catch (error) {
    logError('fetchPostLoadByPostingId', error as Error);
    setPostLoadStore(() => ({
      isError: true,
    }));
  } finally {
    setPostLoadStore(() => ({
      loading: false,
    }));
  }
};

export const removePost = async (postingId: number, tabIndex: number) => {
  try {
    await deletePosting(postingId);

    setOrderStore(({ order }) => ({
      order: {
        ...order,
        loads: [{ ...order.loads[tabIndex], postingId: null }],
      },
    }));
  } catch (error) {
    logError('removePost', error as Error);
    setOrderStore(() => ({
      isError: true,
    }));
  } finally {
    setOrderStore(() => ({
      loading: false,
    }));
  }
};

export const addNewContactToCarrierStoreAndUpdateOrderStore = (
  contact: Contact,
) => {
  setCarrierStore(
    produce((draft) => {
      draft.carrier[orderStore.activeTab.index].contacts = [
        ...draft.carrier[orderStore.activeTab.index].contacts,
        contact,
      ];
    }),
  );
  updateLoadPropertyAtIndex({
    carrierContactId: contact.id,
  });
};

export const enableLoadTracking = async (trackingData: TrackingData) => {
  const response = await enableTracking(trackingData);

  if (Boolean(response?.success)) {
    setOrderStore(
      produce((draft) => {
        draft.order.loads[orderStore.activeTab.index].trackingEnabled = true;
      }),
    );
  }

  return response;
};

export const setOrderToVoid = async (orderId: number) => {
  try {
    const response = await voidOrder(orderId);
    response &&
      setOrderStore(
        produce((draft) => {
          draft.order.status = 'Void';
          draft.order.loads[orderStore.activeTab.index].status = 'Void';
        }),
      );
    return response;
  } catch (error) {
    logError('setOrderToVoid', error as Error);
    return {
      success: false,
      message: `failed to enable load tracking ${error.message}`,
    };
  }
};

export const fetchNearbyCarriers = async (
  payload: NearbyCarrierRequestViewModel,
) => {
  try {
    const response = await getNearbyCarriers(payload);
    if (Array.isArray(response) && response.length > 0) {
      setOrderStore('nearbyCarriers', response);
    }
  } catch (error) {
    handleToast(ToastType.Error, (error as Error).message);
  }
};

export const fetchCustomerContact = async (custumerId: number) => {
  try {
    const response: ICustomerContact[] | undefined =
      await getCustomerContact(custumerId);
    if (response.length > 0) {
      return response;
    }
  } catch (error) {
    if (error instanceof Error) {
      handleToast(ToastType.Error, 'Failed to get Customer Contact');
      throw new Error(`Failed to Customer Contact: ${error.message}`);
    }

    logError('fetchCustomerContact', error as Error);
  }
};

async function GetQuoteInfo(quote: TempQuoteModel) {
  if (quote.quoteSpecifics.requestDetails.customerId !== null) {
    if (quote.quoteSpecifics.requestDetails.customerId !== 0) {
      await loadCustomerInfo(quote.quoteSpecifics.requestDetails.customerId);
    }
  }
  await getCarrierDetail(
    Number(quote.quoteSpecifics.quoteResponse.carrierId),
    0,
  );
}

//for now lets just get this code working
export const populateOrderWithQuote = async (id: string) => {
  setOrderLoading(true);
  const quote = (await getTempQuote(id)) as TempQuoteModel;
  await GetQuoteInfo(quote);
  const address =
    quote.ltlQuote.rateRequest.customerId !== null
      ? ((await getDefaultAddress(
          quote.ltlQuote.rateRequest.customerId,
        )) as unknown as Address)
      : undefined;
  const order =
    quote.quoteSpecifics.requestDetails.originZip === address?.zip
      ? buildLoadFromQuote(quote, address)
      : buildLoadFromQuote(quote);
  setLTLQuote({
    ltlQuote: quote.ltlQuote,
    originZip: quote.quoteSpecifics.requestDetails.originZip,
    originCity: quote.quoteSpecifics.requestDetails.originCity,
    originState: quote.quoteSpecifics.requestDetails.originState,
    originCountry: quote.quoteSpecifics.requestDetails.originCountry,
    destinationZip: quote.quoteSpecifics.requestDetails.destinationZip,
    destinationCity: quote.quoteSpecifics.requestDetails.destinationCity,
    destinationState: quote.quoteSpecifics.requestDetails.destinationState,
    destinationCountry: quote.quoteSpecifics.requestDetails.destinationCountry,
    customerId: quote.quoteSpecifics.requestDetails.customerId ?? undefined,
    customerName: quote.quoteSpecifics.requestDetails.customerName ?? undefined,
    pickupDate: quote.quoteSpecifics.requestDetails.pickupDate,
    loadItems: quote.quoteSpecifics.requestDetails.loadItems,
  });
  updateOrderState(order);
  if (
    quote.ltlQuote.ltlInsurance &&
    quote.ltlQuote.ltlInsurance.shippingSumInsured > 0
  ) {
    const { shippingSumInsured, quoteServiceFee, quotePremium } =
      quote.ltlQuote.ltlInsurance;
    updateCustomerAndVendorPay(
      {
        shippingSumInsured,
        quotePremium,
        quoteServiceFee,
      } as FalveyInsuranceQuoteInLoad,
      'insert',
      shippingSumInsured,
    );
  }
  setOrderLoading(false);
};

export const getOrderTemplates = async () => {
  const response = (await fetchOrderTemplates()) ?? [];
  setOrderStore('orderTemplates', response);
};

export const deleteOrderTemplate = async (id: number) => {
  const response = await deleteOrderTemplateService(id);
  if (Boolean(response)) {
    const newTemplates = orderStore.orderTemplates.filter(
      (template) => template.id !== id,
    );
    setOrderStore('orderTemplates', newTemplates);
  }
};

export const saveOrderTemplate = async (
  name: string,
  isPublic: boolean,
  cb?: () => void,
) => {
  let template: Partial<IOrderViewModel> = cloneDeep(orderStore.order);
  template = omit(template, ['id']);
  template.payments = [];
  await saveOrderTemplateCall(name, isPublic, template, 0, cb);
};

export const mergeOrderWithQuote = async (
  id: string,
  existingOrder: IOrderViewModel,
) => {
  await mergeOrderWithNewQuote(id, existingOrder, null);
};

export const mergeOrderWithNewQuote = async (
  id: string,
  existingOrder: IOrderViewModel,
  ltlQuoteId: number | null,
) => {
  const quote = (await getTempQuote(id)) as TempQuoteModel;
  await GetQuoteInfo(quote);
  quote.ltlQuoteId = ltlQuoteId;
  setLTLQuote({
    ltlQuote: quote.ltlQuote,
    originZip: quote.quoteSpecifics.requestDetails.originZip,
    originCity: quote.quoteSpecifics.requestDetails.originCity,
    originState: quote.quoteSpecifics.requestDetails.originState,
    originCountry: quote.quoteSpecifics.requestDetails.originCountry,
    destinationZip: quote.quoteSpecifics.requestDetails.destinationZip,
    destinationCity: quote.quoteSpecifics.requestDetails.destinationCity,
    destinationState: quote.quoteSpecifics.requestDetails.destinationState,
    destinationCountry: quote.quoteSpecifics.requestDetails.destinationCountry,
    customerId: quote.quoteSpecifics.requestDetails.customerId ?? undefined,
    customerName: quote.quoteSpecifics.requestDetails.customerName ?? undefined,
    pickupDate: quote.quoteSpecifics.requestDetails.pickupDate,
    loadItems: quote.quoteSpecifics.requestDetails.loadItems,
    ltlQuoteId: ltlQuoteId,
  });
  const mergedOrder = mergeOrderQuote(quote, existingOrder);
  if (Boolean(quote.ltlQuote.ltlInsurance)) {
    await getLTLFalveyPricingQuotes({
      declaredValue: quote.ltlQuote.ltlInsurance?.shippingSumInsured,
    });
  }

  updateOrderState(mergedOrder);
  if (quote.ltlQuote.ltlInsurance) {
    const { shippingSumInsured, quoteServiceFee, quotePremium } =
      quote.ltlQuote.ltlInsurance;
    addCustomerPayLineItem({
      shippingSumInsured,
      quotePremium,
      quoteServiceFee,
    });
    const falveyVendorIndex = (orderStore.order.vendors ?? []).findIndex(
      (v) => v.name === 'Falvey Shippers Insurance',
    );
    if (falveyVendorIndex === -1) {
      addVendorPayLineItem(
        {
          shippingSumInsured,
          quotePremium,
          quoteServiceFee,
        },
        quote.ltlQuote.ltlInsurance.shippingSumInsured,
      );
    } else {
      updateVendorPayLineItem(
        {
          shippingSumInsured,
          quotePremium,
          quoteServiceFee,
        },
        quote.ltlQuote.ltlInsurance.shippingSumInsured,
      );
    }
  }
};

export const GetCarrierVerificationLockdownValue = async () => {
  const result = await IsFeatureFlagEnabledByName(
    'CarrierVerificationLockdown',
  );

  setOrderStore(() => ({
    carrierContactLockdownEnabled: result,
  }));
};

export const GetOrderLockFeatureFlag = async () => {
  const result = await getEffectiveIsEnabledForUserByFeatureName(
    'LockConcurrentEditForOrder',
  );
  setOrderStore(() => ({
    orderLockEnabled: result,
  }));
};

export const GetForceUnlockOrderLockFeatureFlag = async () => {
  const result =
    await getEffectiveIsEnabledForUserByFeatureName('CanForceUnlock');
  setOrderStore(() => ({
    forceUnlockOrderEnabled: result,
  }));
};

export const fetchOrderTemplateData = async (
  templateId: number,
  callback?: () => void,
) => {
  try {
    setOrderLoading(true);
    const order = await fetchOrderTemplateCall(templateId);
    if (!Boolean(order)) return;
    order.id = 0;
    order.customerId !== null && (await loadCustomerInfo(order.customerId));
    if (order.loads[0].carrierId != null) {
      await getCarrierFeatures(order.loads[0].carrierId, 0);
      await getCarrierDetail(order.loads[0].carrierId, 0);
    }
    if (order.loads[0].postingId !== null)
      await fetchPostLoadByPostingId(order.loads[0].postingId);
    await getCarrierPlaylists();
    let stopId = -1;
    const updatedLoads = order.loads.map((load, idx) => {
      load.coveredBy = userStore.user.name;
      load.coveredById = userStore.user.id;
      load.id = -(idx + 1);
      load.stops = load.stops?.map((stop) => {
        stop.id = stopId--;
        stop.loadId = load.id;
        stop = omit(stop, 'load');
        if (stop.stopDateTime !== null && stop.stopDateTime !== undefined) {
          if (isDateLessThanCurrentDate(DateTime.fromISO(stop.stopDateTime))) {
            stop.stopDateTime = undefined;
          }
        } else {
          stop.stopDateTime = undefined;
        }
        return stop;
      });
      load.items = load.items?.map((item) => {
        item.id = 0;
        if (load.stops) {
          const firstPickUp = load.stops.find((stop) => stop.pickUp);
          const firstDropOff = load.stops.find((stop) => stop.dropOff);
          item.pickUpId = (firstPickUp && firstPickUp.id) || 0;
          item.dropOffId = (firstDropOff && firstDropOff.id) || 0;
        }
        return item;
      });
      return load;
    });
    order.metadata = []; //setting metadata to empty array as it asks for orderId which is not present in template
    setOrderStore(() => ({
      order: {
        ...order,
        loads: updatedLoads,
      },
    }));
  } catch (error) {
    logError('fetchOrderTemplateData', error as Error);
    setOrderStore(() => ({
      isError: true,
      isCreate: false,
      isTemplateUsedInOrder: false,
    }));
  } finally {
    setOrderStore('loading', false);
    callback && callback();
  }
};

export const updateCarrierStoreContactsWithLoadCarrier = (
  carrierIndex: number,
) => {
  const loadCarrierContact =
    orderStore.order.loads[carrierIndex].carrierContact;
  if (loadCarrierContact && loadCarrierContact.status === 'Inactive') {
    const carrier = carrierStore.carrier[carrierIndex] as Carrier | undefined;
    if (carrier) {
      const carrierContacts = carrier.contacts;
      const newCarrierContacts = [...carrierContacts, loadCarrierContact];
      setCarrierStore({
        carrier: [
          ...carrierStore.carrier.slice(0, carrierIndex),
          {
            ...carrier,
            contacts: newCarrierContacts,
          },
          ...carrierStore.carrier.slice(carrierIndex + 1),
        ],
      });
    }
  }
};
