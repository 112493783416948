import { Card, ToastType } from '@components';
import { DialogBox } from '@components/DialogBox';
import { TextAreaField } from '@components/forms';
import {
  carrierDetailsStore,
  deleteCarrierComment,
  postCarrierComment,
  updateCarrierDetailsState,
} from '@store/carriers';
import {
  addCustomerGeneralComment,
  AddOrEditCommentPayload,
  customerDetailsStore,
  deleteCustomerGeneralComment,
  updateCustomerDetailsState,
  V4ApiResponse,
} from '@store/customers/customerDetails';
import {
  addFactoringCompanyDetailsComment,
  deleteFactoringCompanyDetailsComment,
  factoringCompanyDetailsStore,
  updateFactoringCompanyDetailsState,
} from '@store/factoringCompany/factoringCompanyDetails';
import {
  Comments,
  addCommentInOrderStore,
  deleteCommentInOrderStore,
  deleteOrderComment,
  orderStore,
  postOrderComment,
} from '@store/orders';
import { Divider, Stack, Typography } from '@suid/material';
import { handleToast, isAdmin } from '@utils/utils';
import { DELETE_COMMENT_DIALOG_ID } from '@views/order/constants';
import { DateTime } from 'luxon';
import { Component, JSX, Show, createSignal } from 'solid-js';
import Button from '@components/Button/Button';
import AtgLoader from '@components/AtgLoader';
import {
  claimDataStore,
  deleteCommentInClaimStore,
  setClaimDataStore,
} from '@store/claim';
import {
  deleteProspectComment,
  postProspectComment,
  prospectDetailsStore,
  setProspectDetailsStore,
} from '@store/prospect';

import { SingleCommentCard } from './SingleCommentCard';

const scrollContainerStyle = {
  overflowY: 'scroll',
  maxHeight: '466px',
  '&::-webkit-scrollbar': {
    width: '0.3em',
  },
};

const dividerStyle = { color: '#ccc', margin: '13px 0px' };

export type NewATGOnlyNoteProps = {
  type:
    | 'Carrier'
    | 'Order'
    | 'Customer'
    | 'FactoringCompany'
    | 'Claim'
    | 'Call Log'
    | 'Prospect';
  comments: Comments[];
  cardTitle: string;
  loading?: boolean;
  maxHeight?: string;
  maxLength?: number;
};

export const NewATGOnlyNote: Component<NewATGOnlyNoteProps> = (props) => {
  const [commentIdToDelete, setCommentIdToDelete] = createSignal<number | null>(
    null,
  );
  const [newComment, setNewComment] = createSignal({
    comment: '',
    loading: false,
  });

  const scrollCarrierContainerStyle = {
    overflowY: 'auto',
    maxHeight: props.maxHeight ?? '466px',
  };

  const handleDelete = async () => {
    if (commentIdToDelete() === null) return;
    let res: boolean | undefined = false;
    switch (props.type) {
      case 'Order': {
        res = await deleteOrderComment(commentIdToDelete()!);
        Boolean(res) && deleteCommentInOrderStore(commentIdToDelete()!);
        break;
      }
      case 'Carrier': {
        res = await deleteCarrierComment(commentIdToDelete()!);
        Boolean(res) &&
          updateCarrierDetailsState({
            comments: carrierDetailsStore.carrierDetails?.comments.filter(
              (comment) => comment.id !== commentIdToDelete(),
            ),
          });
        break;
      }
      case 'Customer': {
        res = await deleteCustomerGeneralComment(commentIdToDelete()!);
        Boolean(res) &&
          updateCustomerDetailsState({
            comments: customerDetailsStore.customer.comments.filter(
              (comment) => comment.id !== commentIdToDelete(),
            ),
          });
        break;
      }
      case 'FactoringCompany': {
        res = await deleteFactoringCompanyDetailsComment(commentIdToDelete()!);
        Boolean(res) &&
          updateFactoringCompanyDetailsState({
            comments:
              factoringCompanyDetailsStore.factoringCompanyDetails.comments.filter(
                (comment) => comment.id !== commentIdToDelete(),
              ),
          });
        break;
      }
      case 'Claim': {
        res = await deleteOrderComment(commentIdToDelete()!);
        Boolean(res) && deleteCommentInClaimStore(commentIdToDelete()!);
        break;
      }
      case 'Call Log':
      case 'Prospect':
        res = await deleteProspectComment(commentIdToDelete()!);
        Boolean(res) &&
          setProspectDetailsStore(
            'prospectDetails',
            props.type === 'Prospect' ? 'notes' : 'callLog',
            (comments) => comments.filter((c) => c.id !== commentIdToDelete()),
          );
        break;
      default:
        break;
    }
    Boolean(res) &&
      handleToast(ToastType.Success, 'Comment deleted successfully');
  };

  // eslint-disable-next-line complexity
  const handleAddNewComment = async () => {
    try {
      setNewComment((prev) => ({ ...prev, loading: true }));
      const comment = newComment().comment.trim();

      const payload = {
        comment,
        type: isAdmin() ? 'Admin' : 'User',
      } as AddOrEditCommentPayload;

      let res: V4ApiResponse<Comments> | undefined;

      switch (props.type) {
        case 'Order': {
          res = await postOrderComment({
            ...payload,
            objectId: orderStore.order.id,
          });
          if (res && res.isSuccess) addCommentInOrderStore(res.value);
          break;
        }
        case 'Carrier': {
          const carrierDetails = carrierDetailsStore.carrierDetails;
          if (!carrierDetails) return;
          res = await postCarrierComment({
            ...payload,
            objectId: carrierDetails.id,
          });
          if (res && res.isSuccess) {
            updateCarrierDetailsState({
              comments: [...carrierDetails.comments, res.value],
            });
          }
          break;
        }
        case 'Customer': {
          const customerDetails = customerDetailsStore.customer;
          res = await addCustomerGeneralComment({
            ...payload,
            objectId: customerDetails.id!,
          });
          if (res && res.isSuccess) {
            updateCustomerDetailsState({
              comments: [...customerDetails.comments, res.value],
            });
          }
          break;
        }
        case 'FactoringCompany': {
          const factoringCompanyDetails =
            factoringCompanyDetailsStore.factoringCompanyDetails;
          res = await addFactoringCompanyDetailsComment(
            factoringCompanyDetails.id,
            payload,
          );
          if (res && res.isSuccess) {
            updateFactoringCompanyDetailsState({
              comments: [...factoringCompanyDetails.comments, res.value],
            });
          }
          break;
        }
        case 'Claim': {
          const claimDetails = claimDataStore.claimDetails;
          res = await postOrderComment({
            ...payload,
            objectId: claimDetails?.orderId as number,
          });
          if (res && res.isSuccess)
            setClaimDataStore('claimDetails', 'comments', [
              ...(claimDetails?.comments as Comments[]),
              res.value,
            ]);
          break;
        }
        case 'Call Log':
        case 'Prospect':
          const prospectDetails = prospectDetailsStore.prospectDetails;
          if (!prospectDetails.id) return;
          res = await postProspectComment({
            ...payload,
            id: prospectDetails.id,
            commentType:
              props.type === 'Prospect'
                ? isAdmin()
                  ? 'Admin'
                  : 'User'
                : 'Call Log',
            type: 'Prospect',
          });
          if (Boolean(res)) {
            const updatedComments =
              props.type === 'Prospect'
                ? [...prospectDetails.notes, res]
                : [...prospectDetails.callLog, res];
            setProspectDetailsStore(
              'prospectDetails',
              props.type === 'Prospect' ? 'notes' : 'callLog',
              updatedComments as unknown as Comments[],
            );
          }
          break;
        default:
          break;
      }
      Boolean(res) &&
        handleToast(ToastType.Success, 'Comment added successfully');
    } catch (error) {
      handleToast(ToastType.Error, 'Failed to add comment');
    } finally {
      setNewComment(() => ({ comment: '', loading: false }));
    }
  };

  const handleSubmit = () => {
    const comment = newComment().comment.trim();

    if (comment.length > 0 && !Boolean(newComment().loading)) {
      void handleAddNewComment();
    }
  };

  const handleCommentOnChange = (value: string) => {
    setNewComment((prev) => ({ ...prev, comment: value }));
  };

  const sortedComments = (comments: Comments[]) => {
    const sortedComments = comments.toSorted((a, b) => {
      const timestampA = DateTime.fromISO(a.timestamp!);
      const timestampB = DateTime.fromISO(b.timestamp!);
      return timestampB.diff(timestampA).as('milliseconds');
    });
    const commentsArr: JSX.Element[] = [];
    sortedComments.forEach((comment) => {
      if (comment.operationType === 'Delete') return;
      commentsArr.push(
        <SingleCommentCard
          type={props.type}
          comment={comment}
          setCommentIdToDelete={setCommentIdToDelete}
        />,
      );
    });
    return commentsArr;
  };

  return (
    <Card startTitle={props.cardTitle} raised={true} loading={props.loading}>
      <Show when={newComment().loading}>
        <AtgLoader />
      </Show>
      <div class="mb-3">
        <form>
          <TextAreaField
            name="instructions"
            label=""
            rows={3}
            value={newComment().comment}
            onChange={handleCommentOnChange}
            maxLength={props.maxLength}
          />{' '}
          <Button
            onClick={() => {
              handleSubmit();
            }}
            type="button"
            label="Save"
            sx={{ background: '#468DB5', color: '#FFF' }}
            class="!mt-1"
          />
        </form>
      </div>
      <div class="text-[#123B50] mb-3">
        <Typography>Previous Notes</Typography>
      </div>
      <div>
        {props.comments.length > 0 ? (
          <Stack
            sx={
              props.type === 'Carrier' || props.type === 'Claim'
                ? scrollCarrierContainerStyle
                : scrollContainerStyle
            }
          >
            {...sortedComments(props.comments)}
            <Divider sx={dividerStyle} />
          </Stack>
        ) : (
          'No Comments to display'
        )}
      </div>
      <DialogBox
        id={`${DELETE_COMMENT_DIALOG_ID}-${props.type}`}
        title={'Are you sure you want to delete this note ?'}
        onSubmit={() => handleDelete()}
        onClose={() => setCommentIdToDelete(null)}
      />
    </Card>
  );
};
