import { ItemAccessorial } from '@views/ltl/types';
import { AccessorialsType } from '@views/ltl/LTLForm';
import { LtlQuoteViewModelLegacy, PickUpDropOffItem } from '@store/orders';

import {
  LTLRateQuoteRequest,
  LTLRateQuoteResponse,
  SavedQuoteInfo,
} from './v3types';

export type SavedQuotesState = {
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  items: SavedQuoteInfo[];
  overlay: boolean;
};

export type AccessorialState = {
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  items: ItemAccessorial[];
  overlay: boolean;
};

export type LTLGetRatesPayload = {
  customerId: number | null;
  destinationCity: string;
  destinationCountry: string;
  destinationZip: string;
  destinationState: string;
  originZip: string;
  originCity: string;
  originCountry: string;
  originState: string;
  pickupDate: string | null;
  loadItems: PickUpDropOffItem[];
  accessorials: AccessorialsType[];
};

export type LTLRateQuoteApiResponse = {
  message: string[];
  success: boolean;
  data: LTLRateQuoteResponse[]; // TODO : Create and Change this to the v4Type when use to display the data
};
export type LTLQuotingResultState = {
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  items: LTLRateQuoteResponse[];
  overlay: boolean;
  customerId?: number;
};

export type QuoteSpecifics = {
  quoteResponse: LTLRateQuoteResponse;
  customerId?: number;
  requestDetails: LTLRateQuoteRequest;
};

export type TempQuoteModel = {
  quoteSpecifics: QuoteSpecifics;
  ltlQuote: LtlQuoteViewModelLegacy;
  ltlQuoteId: number | null;
};

export type LTLRateQuoteRequestState = {
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  items: LTLRateQuoteRequest;
  overlay: boolean;
};

export type LTLStoreQuoteResponse = {
  correlationId: string;
};

export type LTLQuoteState = {
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  quote?: LTLQuote;
  overlay: boolean;
  falveyPricingQuote?: FalveyInsuranceQuote;
  declaredValue?: number;
};

//this type will be the type to rule them all, and in darkness bind them
export interface LTLQuote {
  customerName?: string; // these are highly likely needed
  customerId?: number; // these are highly likely needed
  ltlQuote?: LtlQuoteViewModelLegacy; // this is from dbo.LTLQuote
  originZip?: string;
  originState?: string | null;
  originCity?: string | null;
  originCountry?: string | null;
  destinationZip?: string;
  destinationCity?: string | null;
  destinationState?: string | null;
  destinationCountry?: string | null;
  pickupDate?: Date | string | number | null;
  loadItems?: LTLLoadItem[];
  ltlQuoteId?: number | null; //This is ltl.Quote.Id
}

export type LTLLoadItem = {
  nmfc: string | null;
  class?: string | null;
  item?: string;
  description: string;
  quantity: number;
  charge?: null | string;
  weight: number | null;
  height: null | string;
  length: number;
  width: null | string;
  volume?: null | number;
  uom: 'lb(s)' | string;
  hazmat: boolean;
  heightInch: number | null;
  lengthInch: number | null;
  widthInch: number | null;
  pieces: string | null;
  loadItemHazmatDetail?: LTLHazmatDetails | null;
  suggestedClass?: null | string;
  hazmatItems?: null | string;
  hazmatDescription?: null | string;
  type?: string;
  density?: string;
  cubicFeet?: number;
  index?: number;
};

export type LTLHazmatDetails = {
  unNumber?: string;
  packingGroup?: string;
  hazardClass?: string;
  piece?: string;
  contactNumber?: string;
  contactName?: string;
  loadItemId?: number;
  ltlCatalogId?: string;
};

export type FalveyQuotesPayload = {
  shipmentTypeID: string;
  declaredValue: number;
  currency: string;
  shippingMerchandiseDescription: string;
  ltlQuoteId?: string;
  quoteParametersId?: number;
};

export type FalveyInsuranceQuote = {
  insurancePremium: number;
  providerFee: number;
  serviceFee: number;
  excludedItemMatch: string;
  excludedItemIDMatch: string;
};

export type FalveyInsuranceQuoteInLoad = {
  shippingSumInsured: number;
  quotePremium: number;
  quoteServiceFee: number;
};

export enum LTLReferenceNumberTypes {
  Pro = 'Pro',
  Pickup = 'Pickup',
  BOL = 'Bol',
  ProNumber = 'ProNumber',
}

export interface LTLReferenceNumber {
  type: LTLReferenceNumberTypes;
  value: string;
  internalName: string;
  displayName: string;
  sequence: number;
  modifiable: boolean;
}

export interface LTLTrackingData {
  referenceNumbers: LTLReferenceNumber[];
  trackingUrlTemplate: string;
  loadId: number;
}

export interface LTLTrackingDataState {
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  trackingData: LTLTrackingData;
}
export interface TotalLinearFeet {
  loadItems: LoadLineItemDimensions[];
  isStackable: boolean;
}
export interface LoadLineItemDimensions {
  heightInch: number;
  lengthInch: number;
  widthInch: number;
  quantity: number;
  weight: number;
  index: number;
}
export interface GetCalculationsFromLoadItemsApiResponse {
  isSuccess: boolean;
  isFailure: boolean;
  message: string;
  value: LoadItemCalculationTotals;
}
export interface LoadItemCalculationTotals {
  totalLinearFeet: number;
  totalCubicFeet: number;
  loadItemCalculations: LoadItemCalculation[];
}
export interface LoadItemCalculation {
  index: number;
  cubicFeet: number;
}
export type LoadItemCalculationState = {
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  items: LoadItemCalculationTotals;
  overlay: boolean;
};
