import { BasicModal, BasicTable, Button, Typography } from '@components';
import { Column } from '@components/BasicTable/BasicTable';
import { FalveyInsuranceQuoteInLoad } from '@store/ltl/types';
import { closeModal, openModal } from '@store/modals';
import {
  fetchLtlQuoteAccessorials,
  fetchLtlQuoteByLoadId,
  fetchLtlQuoteInsurance,
  fetchLtlQuoteLoadItems,
  LtlQuoteLoadItemViewModel,
  LtlQuoteParametersViewModel,
  LtlQuoteViewModel,
  orderStore,
  updateLoadPropertyAtIndex,
  LtlQuoteAccessorialViewModel,
  FalveyInsuranceQuote,
  fetchLtlQuoteById,
  fetchLtlQuoteParametersByQuoteId,
  fetchLtlQuoteParametersByLoadId,
} from '@store/orders';
import {
  isFalveyInsuranceCommitted,
  updateCustomerAndVendorPay,
} from '@store/orders/helper';
import ControlPointRoundedIcon from '@suid/icons-material/ControlPointRounded';
import RequestPageIcon from '@suid/icons-material/RequestPage';
import SyncIcon from '@suid/icons-material/Sync';
import { Box, Grid, Stack } from '@suid/material';
import { formatDateString } from '@utils/dateFormat';
import { formatAmount } from '@utils/utils';
import { InsuranceUpdateAndConfirmationForm } from '@views/ltl/components/InsuranceUpdateAndConfirmation';
import { batch, createEffect, createSignal, Show } from 'solid-js';
import InfoOutlined from '@suid/icons-material/InfoOutlined';
import { featureFlags } from '@store/featureFlags/featureFlags';
import { isFeatureFlagEnabled } from '@store/featureFlags/store';

import downArrow from '../../../../../assets/downArrow.svg';
import upArrow from '../../../../../assets/upArrow.svg';
import { classes } from './classes';
import { TerminalInfoModal } from './TerminalInfoModal';
import { getAccessorialString, LTLHeaderText } from './helpers';

interface LTLQuoteProps {
  canRateLtl: boolean;
  loading?: boolean;
  status?: string;
  loadId: number;
  quoteId: string;
  legacyQuoteId: string | undefined;
}

const LTLQuote = (props: LTLQuoteProps) => {
  const columns: Column<LtlQuoteLoadItemViewModel>[] = [
    {
      name: 'Qty',
      key: 'quantity',
    },
    {
      name: 'Weight',
      key: 'weight',
    },
    {
      name: 'Class',
      key: 'class',
    },
    {
      name: 'NMFC',
      key: 'nmfc',
    },
    {
      name: 'Dimension',
      key: 'dimensions',
    },
    {
      name: 'Hazmat',
      key: 'hazmat',
      renderCell: (rowData) => <span>{String(rowData.hazmat)}</span>,
    },
  ];

  const [ltlQuoteParams, setLtlQuoteParams] =
    createSignal<LtlQuoteParametersViewModel>({
      id: 0,
      value: false,
      operationType: 'None',
      quoteDate: '',
      originZip: '',
      originCity: '',
      originState: '',
      originCountry: '',
      destinationZip: '',
      destinationCity: '',
      destinationState: '',
      destinationCountry: '',
      linearFeet: 0,
      isStackable: false,
      isLinearFeetOverridden: false,
      createdByName: '',
      provider: '',
      accessorials: [],
      ltlInsuranceQuote: null,
    });

  const [ltlQuote, setLtlQuote] = createSignal<LtlQuoteViewModel>({
    id: 0,
    operationType: 'None',
    quoteDate: '',
    carrierTotal: 0,
    ltlInsurancePolicy: null,
  });

  const [loadItems, setLoadItems] = createSignal<LtlQuoteLoadItemViewModel[]>(
    [],
  );
  const [accessorials, setAccessorials] = createSignal<
    LtlQuoteAccessorialViewModel[]
  >([]);
  const [insuranceQuote, setInsuranceQuote] =
    createSignal<FalveyInsuranceQuote>();

  createEffect(async () => {
    const [quoteParams, quote] =
      props.loadId !== 0
        ? await Promise.all([
            fetchLtlQuoteParametersByLoadId(props.loadId),
            fetchLtlQuoteByLoadId(props.loadId),
          ])
        : await Promise.all([
            fetchLtlQuoteParametersByQuoteId(props.quoteId),
            fetchLtlQuoteById(props.quoteId),
          ]);

    batch(() => {
      setLtlQuoteParams(quoteParams);
      setLtlQuote(quote);
    });
    await fetchAdditionalQuoteDetails(quoteParams.id);
  });

  const fetchAdditionalQuoteDetails = async (quoteParamsId: number) => {
    if (quoteParamsId === 0) return;
    const [loadItems, accessorials, insurance] = await Promise.all([
      fetchLtlQuoteLoadItems(quoteParamsId),
      fetchLtlQuoteAccessorials(quoteParamsId),
      fetchLtlQuoteInsurance(quoteParamsId),
    ]);

    batch(() => {
      setLoadItems(loadItems);
      setAccessorials(accessorials);
      setInsuranceQuote(insurance);
    });
  };

  const formatCurrency = (value: number | undefined) => {
    if (typeof value === 'number' && isFinite(value) && value > 0) {
      return `$${value.toFixed(2)}`;
    }
    return '$ --.--';
  };

  const getVendorInsurance = () => {
    return getInsurancePricingFromLoad()?.premium ?? 0;
  };

  const getAgentPriceWithInsurace = () => {
    let totalAgentCost = 0;
    if (insuranceQuote()?.id !== 0) {
      totalAgentCost += insuranceQuote()?.premium ?? 0;
    } else {
      totalAgentCost += getVendorInsurance();
    }
    return formatAmount((totalAgentCost += ltlQuote().carrierTotal));
  };

  const getCustomerInsurance = () => {
    const insurancePricing = getInsurancePricingFromLoad();
    return (
      (insurancePricing?.premium ?? 0) + (insurancePricing?.serviceFee ?? 0)
    );
  };

  const getCustomerPriceWithInsurace = () => {
    let totalCustomerCost = 0;
    if (insuranceQuote()?.id !== 0) {
      totalCustomerCost =
        (insuranceQuote()?.premium ?? 0) + (insuranceQuote()?.serviceFee ?? 0);
    } else {
      totalCustomerCost = getCustomerInsurance();
    }
    return formatAmount(
      totalCustomerCost + (ltlQuote().customerTotal as number),
    );
  };

  const getInsuranceRate = () => {
    return insuranceQuote()?.id !== 0
      ? formatAmount(insuranceQuote()?.premium)
      : '--';
  };

  const getInsuranceAndFees = () => {
    if (insuranceQuote()?.id !== 0) {
      return formatAmount(
        (insuranceQuote()?.premium ?? 0) + (insuranceQuote()?.serviceFee ?? 0),
      );
    }
  };

  const renderFalveyInsuranceSection = () => {
    const declaredValue = insuranceQuote()?.shippingSumInsured;
    const isAssigned = ['Assigned', 'Unassigned', 'Can Get'].includes(
      props.status as string,
    );
    const isDeclaredValuePresent = Boolean(declaredValue);

    const amountJsx = (
      <Typography variant="body1" component={'span'} class="!mr-2">
        {formatAmount(declaredValue)}
      </Typography>
    );

    const buttonsJsx = (
      <Show
        when={declaredValue}
        fallback={
          <Button
            label="Add Insurance"
            variant="contained"
            startIcon={<ControlPointRoundedIcon />}
            class="!bg-[#026EA1]"
            onClick={() => openModal('falvey-insurance')}
          />
        }
      >
        {amountJsx}
        <Show when={!isFalveyInsuranceCommitted()}>
          <Button
            label="Update"
            variant="contained"
            startIcon={<SyncIcon />}
            class="!bg-[#026EA1]"
            onClick={() => openModal('falvey-insurance')}
          />
        </Show>
      </Show>
    );
    if (isAssigned && isFeatureFlagEnabled(featureFlags.falvey)()) {
      return [LTLHeaderText('Declared Value'), buttonsJsx];
    }

    if (isDeclaredValuePresent) {
      return [LTLHeaderText('Declared Value'), amountJsx];
    }

    return [];
  };

  // To get insurance pricing from load after dispatch
  const getInsurancePricingFromLoad = () => {
    return insuranceQuote()?.id !== 0 ? insuranceQuote() : undefined;
  };

  return (
    <>
      <div>
        <div class="flex items-center space-x-2">
          <div class="text-[#123b50] text-xl font-medium">
            LTL Quote - {ltlQuote().carrierQuoteId}
          </div>
          <div class="text-[#666666] text-sm flex space-x-1">
            <div class="font-medium"> Created By:</div>
            <div>{ltlQuoteParams().customerName}</div>
          </div>
          <div class="text-[#666666] text-sm flex space-x-1">
            <div class="font-medium"> Date Created:</div>
            <div>{formatDateString(ltlQuote().quoteDate, 'MM/dd/yyyy')}</div>
          </div>
        </div>
        <Grid container>
          <Grid item xs={3}>
            {LTLHeaderText(ltlQuote().carrierName as string)}
            <img
              class="max-w-40"
              src={`https://dev.atgfr8.com${ltlQuote().logoImagePath}`}
              alt={ltlQuote().carrierName}
            />
          </Grid>
          <Grid item xs={3}>
            {LTLHeaderText('Accessorials')}
            <Stack class={classes.AccessorialsRow}>
              <Grid container>
                <Grid item xs={1.5}>
                  <img src={upArrow} alt="Up Arrow" />
                </Grid>
                <Grid item xs={10.5}>
                  <Typography variant="body1" class="!text-xs">
                    {getAccessorialString(
                      accessorials()
                        .filter((acc) => acc.isPickup === true)
                        .map((acc) => acc.accessorialName),
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1.5}>
                  <img src={downArrow} alt="Down Arrow" />
                </Grid>{' '}
                <Grid item xs={10.5}>
                  <Typography variant="body1" class="!text-xs">
                    {getAccessorialString(
                      accessorials()
                        .filter((acc) => acc.isDelivery === true)
                        .map((acc) => acc.accessorialName),
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Typography variant="body1" class="!text-xs">
                {getAccessorialString(
                  accessorials()
                    .filter((acc) => acc.isGlobal === true)
                    .map((acc) => acc.accessorialName),
                )}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            {LTLHeaderText('Max Carrier Liabillity')}
            <div class="my-[5px] text-[#666666]">
              <span class="text-[#000000DE] font-bold text-xs"> New: </span>
              {formatCurrency(ltlQuote().maxNewLiability)}
            </div>
            <div class="my-[5px] text-[#666666] ">
              <span class="text-[#000000DE] font-bold text-xs"> Used: </span>
              {formatCurrency(ltlQuote().maxUsedLiability)}
            </div>
            <div class="flex space-x-1 items-center">
              <InfoOutlined fontSize="small" sx={{ color: '#026ea1' }} />
              <div
                class="text-[#026ea1] font-medium cursor-pointer text-xs"
                onClick={() => {
                  openModal('terminal-info-modal');
                }}
              >
                View Terminal Info
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            {LTLHeaderText('Cost')}
            <div class="flex">
              <Stack>
                <div class="font-semibold text-sm">Agent</div>
                <div class="text-[#666666] text-xs ml-4">Carrier Rate</div>
                <Show when={insuranceQuote()?.shippingSumInsured !== 0}>
                  <div class="text-[#666666] text-xs ml-4">Insurance Rate</div>
                </Show>
              </Stack>
              <Stack class="!ml-auto">
                <span class="text-[#248670] font-medium">
                  {getAgentPriceWithInsurace()}
                </span>
                <span class="text-xs ml-auto text-[#666666]">
                  {formatAmount(ltlQuote().carrierTotal)}
                </span>
                <Show when={insuranceQuote()?.shippingSumInsured !== 0}>
                  <span class="text-xs ml-auto text-[#666666]">
                    {getInsuranceRate()}
                  </span>
                </Show>
              </Stack>
            </div>
            {Boolean(ltlQuote().customerTotal) && (
              <Box class="flex mt-2">
                <Stack>
                  <div class="font-semibold text-sm">Customer</div>
                  <Show when={insuranceQuote()?.shippingSumInsured !== 0}>
                    <div class="text-[#666666] text-sm ml-4">
                      Insurance & Fees
                    </div>
                  </Show>
                </Stack>
                <Stack class="!ml-auto">
                  <span class="text-[#26856f] font-medium">
                    {getCustomerPriceWithInsurace()}
                  </span>
                  <Show when={insuranceQuote()?.shippingSumInsured !== 0}>
                    <span class="text-sm ml-auto text-[#666666]">
                      {getInsuranceAndFees()}
                    </span>
                  </Show>
                </Stack>
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3}>
            {LTLHeaderText('Transit Days')}
            <Typography variant="body1">{ltlQuote().transitDays}</Typography>
          </Grid>
          <Grid item xs={3}>
            {LTLHeaderText(' Est. Transit Days')}
            <Typography variant="body1">{ltlQuote().serviceType}</Typography>
          </Grid>
          <Grid item xs={3}>
            {LTLHeaderText('Movement Type')}
            <Typography variant="body1">
              {ltlQuote().movementType ?? '--'}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            {renderFalveyInsuranceSection()}
          </Grid>
        </Grid>
        <div class="pt-5">
          <BasicTable<LtlQuoteLoadItemViewModel>
            noEmptyRender
            columns={columns}
            rows={loadItems()}
          />
        </div>
        <div class="flex justify-end my-2">
          {props.canRateLtl && (
            <button
              onClick={() => {
                openModal('ltl-quoting-modal');
              }}
              class={classes.requoteShipmentBtn}
            >
              <RequestPageIcon />
              Requote Shipment
            </button>
          )}
        </div>
      </div>
      <BasicModal id={'falvey-insurance'} width={'650px'} footer={false}>
        <InsuranceUpdateAndConfirmationForm
          items={loadItems().map((ltlQuoteItem, ind) => {
            let loadItem; //TODO: investigate if this is correct, doing this order store stuff
            if (orderStore.order.loads[orderStore.activeTab.index].items) {
              loadItem =
                orderStore.order.loads[orderStore.activeTab.index].items![ind];
            }
            return {
              ...ltlQuoteItem,
              description: loadItem?.description,
            };
          })}
          onConfirmation={(val, type, declaredVal) => {
            updateCustomerAndVendorPay(
              val as FalveyInsuranceQuoteInLoad,
              type as string,
              declaredVal as number,
            );
            closeModal('falvey-insurance');
            if (insuranceQuote()?.id !== 0 && val) {
              updateLoadPropertyAtIndex({
                ltlQuoteParameters: {
                  ...ltlQuoteParams(),
                  ltlInsuranceQuote: {
                    ...insuranceQuote(),
                    shippingSumInsured: declaredVal as number,
                    premium: val.quotePremium,
                    serviceFee: val.quoteServiceFee,
                  },
                },
              });
            }
          }}
          onCancel={() => {
            closeModal('falvey-insurance');
          }}
          declaredValue={insuranceQuote()?.shippingSumInsured}
          customerCost={ltlQuote().customerTotal}
          carrierCost={ltlQuote().carrierTotal}
          updateDeclaredValue={(val) => {
            if (insuranceQuote()?.id !== 0) {
              updateLoadPropertyAtIndex({
                ltlQuoteParameters: {
                  ...ltlQuoteParams(),
                  ltlInsuranceQuote: {
                    ...insuranceQuote(),
                    shippingSumInsured: val,
                    premium: insuranceQuote()?.premium ?? 0,
                    serviceFee: insuranceQuote()?.serviceFee ?? 0,
                  },
                },
              });
            }
          }}
          falveyInsuranceQuote={{
            shippingSumInsured: insuranceQuote()?.shippingSumInsured ?? 0,
            quotePremium: insuranceQuote()?.premium ?? 0,
            quoteServiceFee: insuranceQuote()?.serviceFee ?? 0,
          }}
          ltlQuoteId={props.legacyQuoteId}
          quoteParametersId={ltlQuoteParams().id}
        />
      </BasicModal>
      <TerminalInfoModal
        modalId={'terminal-info-modal'}
        onClose={closeModal}
        quoteId={
          props.quoteId && props.quoteId.trim() !== ''
            ? props.quoteId
            : String(ltlQuote().id)
        }
      />
    </>
  );
};

export default LTLQuote;
